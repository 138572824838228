import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../store/reducers/cart'; // Ensure this path is correct
import './OrderConfirmation.css'; // Ensure you have styles here
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Show toast notification on component mount
  useEffect(() => {
    toast.success('Thank you for placing the order!');
  }, []);

  // Handle Return to Home and clear the cart
  const handleReturnHome = () => {
    dispatch(clearCart()); // Clear the cart when navigating to home
    navigate('/'); // Navigate back to the home page
  };

  return (
    <div className="confirmation-page">
      <div className="thank-you-logo">Thank you</div>
      <h2>Thank you for placing your order.</h2>
      <p>
        Your order has been successfully placed and is being processed. We will notify you when your order is shipped. Your order will be delivered within 7 days.
      </p>
      <button className="return-home-button" onClick={handleReturnHome}>
        Return To Home
      </button>
      <footer className="footer-text">
        <p>Copyright © <a href="#">Tajam</a> 2024.</p>
      </footer>

      {/* Add ToastContainer here to display the toast */}
      <ToastContainer />
    </div>
  );
};

export default ConfirmOrder;
