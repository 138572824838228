import React, { useEffect, useState } from 'react';
import { AiOutlineDollar } from 'react-icons/ai';
import { FaRegEye } from 'react-icons/fa';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Loader from '../../common/Loader/Loader';
import Modal from 'react-modal';
import client from '../../GraphQL/ApolloClient';
import { CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY } from '../../GraphQL/mutation';
import ProductDetails from '../../components/ProductDetails';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addItemToCart } from '../../store/reducers/cart';
import LocationBar from './Location';
import ProductCard from './ProductCard';

const DEFAULT_LATITUDE = 24.850707597571677;
const DEFAULT_LONGITUDE = 67.2693574847137;

const Products = () => {
  const [products, setProducts] = useState([]); 
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [latitude, setLatitude] = useState(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState(DEFAULT_LONGITUDE);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [location, setLocation] = useState('Current Location'); 
  const [postalCode, setPostalCode] = useState(''); 

  const handleUpdateProducts = (updatedProducts) => {
    console.log('Updated Products:', updatedProducts);
    setFilteredProducts(updatedProducts );
  };

  const updateLocation = (newLocation, newPostalCode) => {
    setLocation(newLocation);
    setPostalCode(newPostalCode);
  };

  const updateFilteredProducts = (newProducts) => {
    setFilteredProducts(newProducts);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          fetchLocationData(position.coords.latitude, position.coords.longitude);
        },
        (error) => console.log('Error fetching location:', error.message)
      );
    }
  }, []);

  const fetchLocationData = async (lat, lon) => {
    const API_KEY = '63c4ee09e349437992c7a1aac53c7c7b';
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lon}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results.length > 0) {
        const { city, postcode } = data.results[0].components;
        setLocation(city || 'Unknown Location');
        setPostalCode(postcode || '');
      }
    } catch (error) {
      console.log('Error fetching location data:', error);
    }
  };

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const addToCartHandler = (product) => {
    const isAlreadyInCart = cartItems.some((item) => item.id === product.slug);

    if (isAlreadyInCart) {
      toast.error('Product is already in the cart!');
    } else {
      dispatch(
        addItemToCart({
          id: product.slug,
          name: product.productName,
          imageName: product.listImagePath?.[0] || '',
          price: product.discountedPrice,
          quantity: 1,
        })
      );
      toast.success('Product added to cart successfully!');
    }
  };

  const fetchBusinessesWithinRange = async () => {
    setLoading(true);
    try {
      const response = await client.query({
        query: CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY,
        variables: { latitude, longitude, pageNo, pageSize },
      });
      const allProducts = response?.data?.calculateBusinessesWithinRangeByLocation?.data?.businesses?.flatMap((business) =>
        business.businessDetails.inventoryList.flatMap((inventory) =>
          inventory.products.map((product) => ({
            ...product,
            businessId: business.businessId,
            category: inventory.category.categoryName,
          }))
        )
      );
      setProducts(allProducts || []);
    } catch (error) {
      console.error('Error fetching businesses:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinessesWithinRange();
  }, [latitude, longitude, pageNo, pageSize]);

  const openModal = (slug) => {
    setSelectedSlug(slug);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSlug(null);
  };

const productsToDisplay = filteredProducts !== null ? filteredProducts:products;

  return (
    <>
      <Navbar />
      <div className="font-poppins container m-auto py-[6rem]">
        <div className="mb-6" style={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
          <LocationBar 
          onUpdateProducts={handleUpdateProducts}
           location={location} postalCode={postalCode} onUpdateLocation={updateLocation} />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="prod-loop flex flex-wrap justify-center items-center">
            {productsToDisplay.length === 0 ? (
              <div className="no-products-message text-center text-xl text-red-600">No products available at this location.</div>
            ) : (
              productsToDisplay.map((product, index) => (
                <ProductCard
                key={index}
                product={filteredProducts === null ? product : product.product} 
                onAddToCart={addToCartHandler}
               
              />
              ))
            )}
          </div>
        )}

        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Products;
