import React, { useState } from 'react';
import enIcon from '../../assests/en.png';
import './Header.css';
import headerData from './header.json'; // Assuming this is the correct path

const LanguageDropdown = () => {
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const languageSubmenu = headerData.header.icons.find(icon => icon.type === "language")?.submenu || [];

  return (
    <div
      className="en-icon-group"
      onMouseEnter={() => setLanguageDropdownVisible(true)}
      onMouseLeave={() => setLanguageDropdownVisible(false)}
    >
      <img src={enIcon} alt="Language Icon" />

      {languageDropdownVisible && (
        <div className="language-dropdown">
          <h4>Change language <a href="#">Learn More</a></h4>
          <form>
            {languageSubmenu.map((language, index) => (
              <label key={index}>
                <input type="radio" name="language" className='inp' value={language.value} />
                {language.label}
              </label>
            ))}
          </form>
          <hr />
          <h4>Change currency <a href="#">Learn More</a></h4>
          <p>$ - USD - US Dollar <a href="#">Change</a></p>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
