
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/authContext';
import { currentWebInterface } from '../../store/reducers/main';
import { GET_USER_BY_ID } from '../../GraphQL/mutation';
import { FaUser, FaCog } from 'react-icons/fa';
import './Header.css';
import signInIcon from '../../assests/signIn.png';
import { useLazyQuery } from '@apollo/client';

const SignInDropdown = ({ dropdownContent }) => {
  const handleLogout = () => {
    // Remove specific user data from local storage
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userData');
    logout();
    navigate('/');
  };
   
  const [getUserById, { data: userData }] = useLazyQuery(GET_USER_BY_ID);

  const { isAuthenticated, logout } = useAuth();
  const [username, setUsername] = useState('');
//   const username = useSelector((state) => state.user.username);// Assuming the username is stored in Redux
  const [isHovered, setIsHovered] = useState(false);
  const { webInterface } = useSelector((state) => state.main);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const buttonText = webInterface === 'business' ? 'Switch to Business' : 'Switch to Customer';

  const toggleWebInterface = () => {
    const switchedInterface = webInterface === 'business' ? 'customer' : 'business';
    dispatch(currentWebInterface(switchedInterface));
    navigate(switchedInterface === 'business' ? '/' : '/business/profile');
  };


  // Fetch username on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getUserById();
  }, [getUserById]);
  // Set username from API response or local storage
  useEffect(() => {
    if (userData) {
      const fetchedUsername = userData.getUserById?.userName?.split('@')[0] || null;
      const userInfo = JSON.parse(localStorage.getItem('userInfo')); // Assuming userInfo is stored as a JSON string

      if (fetchedUsername) {
        setUsername(fetchedUsername);
      } else if (userInfo && userInfo.email) {
        setUsername(userInfo.email.split('@')[0]); // Extract username from email
      }
    }
  }, [userData]);
console.log(username);
  return (
    <div
      className="icon-group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isAuthenticated ? (
        <>
          <FaUser size={20} color="white" className="custom-icon" />
          <span className="icon-label">{username}</span>
        </>
      ) : (
        <>
          <img src={signInIcon} alt="Sign In" className="custom-icon" />
          <span className="icon-label">Sign In</span>
        </>
      )}

      {isHovered && (
        <div className="dropdown-content">
          {isAuthenticated ? (
            <>
              <div className="left-section">
                {dropdownContent.authenticated.menuItems.map((item, index) => (
                  <a key={index} href={item.link} className="menu-item">
                    {item.label}
                  </a>
                ))}
              </div>
              <div className="right-section">
                <h1 className="list">
                  <b>Your Lists</b>
                </h1>
                {dropdownContent.authenticated.yourLists.map((list, index) => (
                  <a key={index} href={list.link} className="menu-item">
                    {list.label}
                  </a>
                ))}
                <br />
                <button className="sign-in" onClick={handleLogout}>
                  {dropdownContent.authenticated.logoutButton.label}
                </button>
              </div>
            </>
          ) : (
            <>
            <div className="unlogged-section">
              {dropdownContent.unauthenticated.options.map((option, index) => (
                <React.Fragment key={index}>
                  {/* Conditionally render based on the label */}
                  {option.label === 'Sign in' ? (
                    <button className="sign-in">
                      <a href={option.link}>{option.label}</a>
                    </button>
                  ) : option.label === 'Not a member? Join Now' ? (
                    <p className="member">
                      {option.label} <a href="/signup">Join Now</a>
                    </p>
                  ) : (
                    <button
                      onClick={() => navigate(option.link)}
                      className="flex items-center space-x-2 text-[#313131] text-lg font-poppins cursor-pointer w-full text-left"
                    >
                      {option.icon && React.createElement(require('react-icons/fa')[option.icon], { className: "text-[#273B60] text-lg" })} {/* Dynamic icons can be added here */}
                      <span className="itemsna">{option.label}</span>
                    </button>
                  )}
                </React.Fragment>
              ))}
            </div>
          </>
          )}
        </div>
      )}
    </div>
  );
};

export default SignInDropdown;
