import React from 'react';
import { CiStar } from 'react-icons/ci';

export const ProductCard = ({ product }) => (
  <div className="prod-wrapper cursor-pointer">
    <div className="relative">
      <div className="img flex h-48 justify-center items-center text-center bg-[#EDEDED] rounded-lg overflow-hidden">
        <img
          src={product.imagePaths[0]}
          alt={product.productName}
          className="w-full h-full object-cover"
        />
      </div>
      <button className="absolute top-2 right-2 bg-green-500 text-white text-xs font-bold py-1 px-2 rounded">
        + Add
      </button>
    </div>
    <h4 className="prod-title text-sm font-semibold mt-2 text-[#273B60] truncate">
      {product.productName}
    </h4>
    <div className="prod-info mt-1">
      <h4 className="prod-price text-sm font-semibold text-[#273B60]">
        ${product.discountedPrice.toFixed(2)}
        <span className="text-xs text-gray-500 line-through ml-1">
          ${product.price.toFixed(2)}
        </span>
      </h4>
      <div className="flex items-center">
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <CiStar key={i} className="text-sm text-[#FDCE0C]" />
          ))}
        </div>
        <span className="text-xs text-gray-500 ml-1">(33)</span>
      </div>
      <p className="text-xs text-green-600">Many in stock</p>
    </div>
  </div>
);

export const ProductSkeleton = () => (
  <div className="prod-wrapper">
    <div className="relative">
      <div className="animate-pulse bg-gray-200 h-48 w-full rounded-lg mb-2"></div>
      <div className="absolute top-2 right-2 animate-pulse bg-green-500 h-8 w-16 rounded"></div>
    </div>
    <div className="animate-pulse bg-gray-200 h-4 w-3/4 mb-2"></div>
    <div className="animate-pulse bg-gray-200 h-4 w-1/4"></div>
  </div>
);
