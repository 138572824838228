import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import './AddProduct.css';
// import { useLocation } from 'react-router-dom';
import { ADD_CATEGORY, GET_CATEGORIES_BY_BUSINESS } from '../../GraphQL/mutation';
import { useParams } from 'react-router-dom';

// GraphQL Mutation for adding the product
import { ADD_PRODUCT_BY_CATEGORY } from '../../GraphQL/mutation'; // Assuming you have the mutation defined here

const AddProduct = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const { businessId } = useParams();
  // Form field states
  const [productName, setProductName] = useState('');
 
  const [description, setDescription] = useState('');
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [likedCount, setLikedCount] = useState(0);
  const [purchasedCount, setPurchasedCount] = useState(0);
  const [status, setStatus] = useState('AVAILABLE');
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  
  const [addProductByCategory, { loading, error, data }] = useMutation(ADD_PRODUCT_BY_CATEGORY);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

   // Fetch categories on component load
   const { loading: loadingCategories, data: categoryData, refetch } = useQuery(
    GET_CATEGORIES_BY_BUSINESS,
    {
      variables: { businessId: businessId || '672fb6e1a1f6512b8e0b39d7' },
    }
  );

  // Mutation to add category
  const [addCategory] = useMutation(ADD_CATEGORY);

  useEffect(() => {
    if (categoryData) {
      setCategories(categoryData.getCategoriesByBusiness.data);
    }
  }, [categoryData]);

  const handleAddCategory = async () => {
    try {
      await addCategory({
        variables: {
          businessId: businessId || '672fb6e1a1f6512b8e0b39d7',
          category: newCategory,
        },
      });
      setShowAddCategory(false);
      setNewCategory('');
      refetch(); // Refresh the categories after adding a new one
      alert('Category added successfully');
    } catch (error) {
      console.error('Error adding category:', error);
      alert('Failed to add category');
    }
  };
  const handleAddProduct = async () => {
    // Prepare the image paths array
    const imagePaths = images.map((image) => image.name || image);
  
    // Construct the input object for the mutation
    const inputData = {
      businessId: businessId ||'6730cbc5b88fd57797d4e3db',
      categoryName: category || 'Biryani',
      product: {
        productName,
        description,
        quantity: parseInt(stock, 10),
        status,
        price: parseFloat(price),
        discount: parseFloat(discount),
        discountedPrice: parseFloat(discountedPrice),
        likedCount: parseInt(likedCount, 10),
        purchasedCount: parseInt(purchasedCount, 10),
        imagePaths,
        listImagePath: imagePaths,
      },
    };
  
    // Log the data before sending
    console.log('Data being sent to API:', JSON.stringify(inputData, null, 2));
  
    try {
      const response = await addProductByCategory({
        variables: {
          input: inputData,
        },
      });
  
      console.log('API Response:', response);
      alert('Product added successfully');
    } catch (err) {
      console.error('Error adding product:', err);
      alert('Error adding product');
    }
  };
  

  return (
    <div className="add-product-container">
      <h2 className="page-title">Add Product</h2>
      
      {/* Product Info Section */}
      <div className="collapsible-section">
        <div className="section-header" onClick={() => toggleSection('info')}>
          <span>Product Info</span>
          <span>{expandedSection === 'info' ? '▲' : '▼'}</span>
        </div>
        {expandedSection === 'info' && (
          <div className="section-content">
               {/* Category Field */}
      <div className="form-group">
        <label htmlFor="category">Category</label>
        <div className="category-dropdown">
          <select
            id="category"
            className="form-control"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {categories.map((cat, index) => (
              <option key={index} value={cat}>{cat}</option>
            ))}
          </select>
          <button className="add-category-btn" onClick={() => setShowAddCategory(true)}>
            +
          </button>
        </div>
      </div>

      {/* Add New Category Modal */}
      {showAddCategory && (
       
          <div className="modal-content">
            <input
              type="text"
              className="form-control"
              placeholder="Enter new category"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
            <button onClick={handleAddCategory} className="add-btn">Add</button>
            <button onClick={() => setShowAddCategory(false)} className="cancel-btn">Cancel</button>
          </div>
      
      )}

            {/* Product Name Field */}
            <div className="form-group">
              <label htmlFor="productName">Product Name</label>
              <input
                type="text"
                id="productName"
                className="form-control"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Enter product name"
              />
            </div>

            {/* Product Description Field */}
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter product description"
              />
            </div>
          </div>
        )}
      </div>

      {/* Product Details Section */}
      <div className="collapsible-section">
        <div className="section-header" onClick={() => toggleSection('details')}>
          <span>Product Details</span>
          <span>{expandedSection === 'details' ? '▲' : '▼'}</span>
        </div>
        {expandedSection === 'details' && (
          <div className="section-content">
            {/* Stock Field */}
            <div className="form-group">
              <label htmlFor="stock">Stock</label>
              <input
                type="number"
                id="stock"
                className="form-control"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                placeholder="Enter stock quantity"
              />
            </div>

            {/* Price Field */}
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input
                type="number"
                id="price"
                className="form-control"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Enter price"
              />
            </div>

            {/* Discount Field */}
            <div className="form-group">
              <label htmlFor="discount">Discount (%)</label>
              <input
                type="number"
                id="discount"
                className="form-control"
                value={discount}
                onChange={(e) => {
                  setDiscount(e.target.value);
                  setDiscountedPrice((price - price * (e.target.value / 100)).toFixed(2));
                }}
                placeholder="Enter discount percentage"
              />
            </div>

            {/* Discounted Price Field */}
            <div className="form-group">
              <label htmlFor="discountedPrice">Discounted Price</label>
              <input
                type="number"
                id="discountedPrice"
                className="form-control"
                value={discountedPrice}
                disabled
                placeholder="Calculated discounted price"
              />
            </div>

            {/* Liked Count */}
            <div className="form-group">
              <label htmlFor="likedCount">Liked Count</label>
              <input
                type="number"
                id="likedCount"
                className="form-control"
                value={likedCount}
                onChange={(e) => setLikedCount(e.target.value)}
              />
            </div>

            {/* Purchased Count */}
            <div className="form-group">
              <label htmlFor="purchasedCount">Purchased Count</label>
              <input
                type="number"
                id="purchasedCount"
                className="form-control"
                value={purchasedCount}
                onChange={(e) => setPurchasedCount(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>

      {/* Product Images Section */}
      <div className="collapsible-section">
        <div className="section-header" onClick={() => toggleSection('images')}>
          <span>Product Images</span>
          <span>{expandedSection === 'images' ? '▲' : '▼'}</span>
        </div>
        {expandedSection === 'images' && (
          <div className="section-content">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => setImages([...e.target.files])}
            />
            <div className="image-preview">
              {images && Array.from(images).map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  className="preview-image"
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Add Product Button */}
      <button className="add-product-button" onClick={handleAddProduct} disabled={loading}>
        {loading ? 'Adding Product...' : 'Add Product'}
      </button>

      {error && <p className="error-message">{error.message}</p>}
      {data && <p className="success-message">{data.addProductByCategory.message}</p>}
    </div>
  );
};

export default AddProduct;
