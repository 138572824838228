import React from 'react';
import './Header.css';
import logo from '../../assests/tajam-header-logo.png';
import reorderIcon from '../../assests/recorderIcon.png';
import savingsIcon from '../../assests/savingIcon.png';
import signInIcon from '../../assests/signIn.png';
import cartIcon from '../../assests/cartIcon.png';

import SearchIcon from '@mui/icons-material/Search';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import DepartmentDropdown from './DepartmentDropdown';
import LanguageDropdown from './LanguageDropdown';
import SignInDropdown from './SignInDropdown';

import headerData from './header.json';

const Header = () => {
  const iconSrcMapping = {
    reorderIcon,
    savingsIcon,
    signInIcon,
    cartIcon,
  };

  return (
    <header className="header">
      <div className="header-top">
        <span className="sale-announcement">
          Experience The Ultimate MEGA SALE On Our ECommerce Site! Discover Unbeatable Discounts Across All Categories, With Up To 80% Off On Top Brands And Best-Selling Items!
        </span>
      </div>

      <div className="header-main">
        <img src={logo} alt={headerData.header.logo.alt} className="logo" />

        <div className="location">
          <LocationOnOutlinedIcon className="location-icon" />
          <div className="val">
            <span>{headerData.header.location.text}</span>
            <h1 className="location-value"><b>USA,Texas</b></h1>
          </div>
        </div>

        <DepartmentDropdown />

        <div className="searching-bar">
          <SearchIcon className="searching-icon" />
          <input type="text" placeholder="Search" className="searching-input" />
        </div>

        <div className="icons">
          {headerData.header.icons.map((icon, index) => {
            if (icon.component === "LanguageDropdown") {
              return <LanguageDropdown key={index} alt={icon.alt} />;
            } else if (icon.component === "SignInDropdown") {
              return (
                <SignInDropdown
                  key={index}
                  icon={signInIcon}
                  label={icon.label}
                  dropdownContent={icon.dropdownContent}
                />
              );
            } else {
              return (
                <div className="icon-group" key={index}>
                  <img src={iconSrcMapping[icon.icon]} alt={icon.alt || icon.label} className="custom-icon" />
                  <span className="icon-label">{icon.label}</span>
                </div>
              );
            }
          })}
          <div className="vertical-line"></div>
        </div>
      </div>

      <div className="header-bottom">
  <div className="club">
    <span>{headerData.header.clubInfo.title}</span>
    <span className="club-location">{headerData.header.clubInfo.location}</span>
  </div>
  <div className="menu">
    {headerData.header.menu.map((menuItem, index) => {
      if (menuItem.label === "More") {
        return (
          <div key={index} className="menu-link more">
            {menuItem.label}
            {menuItem.submenu && (
              <div className="submenu">
                {menuItem.submenu.map((subItem, subIndex) => (
                  <a key={subIndex} href={subItem.href} className="submenu-link">
                    {subItem.label}
                  </a>
                ))}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div key={index} className="menu-link">
            <a href={menuItem.href}>{menuItem.label}</a>
          </div>
        );
      }
    })}
  </div>
</div>

    </header>
  );
};

export default Header;
