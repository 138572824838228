import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addItemToCart } from '../store/reducers/cart'; // Adjust the import path as necessary

const Product = ({ prodId, productsData }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  // Access cart items from Redux store to check for duplicates
  const cartItems = useSelector((state) => state.cart.cartItems);

  // Debug: Log prodId and productsData when the component renders
  console.log("Product ID passed to modal:", prodId);
  console.log("Products data passed to modal:", productsData);
  // const imgpath=`${product.listImagePath[0]}';
// console.log("image ",imgpath)
  // Find the product details by ID
  const product = productsData.find((item) => item.productId === prodId);

  // Debug: Log if the product is found or not
  console.log("Product found:", product);

  // If no product is found, return null to avoid rendering the modal
  if (!product) return <p>No product found for the given ID: {prodId}</p>;

  const handleIncrement = () => {
    setQuantity((prev) => Math.min(prev + 1, 50)); // Max limit of 50
  };

  const handleDecrement = () => {
    setQuantity((prev) => Math.max(prev - 1, 1)); // Min limit of 1
  };

  const addToCartHandler = () => {
    const isAlreadyInCart = cartItems.some((item) => item.id === product.productId); // Ensure correct comparison
  
    if (isAlreadyInCart) {
      toast.error('Product is already in the cart!');  // Show error toast if product is already in cart
    } else {
      dispatch(
        addItemToCart({
          id: product.productId, // Use the unique product ID
          name: product.productName,
          price: product.price,
          quantity: quantity,
          imageName: product.listImagePath && product.listImagePath.length > 0 ? product.listImagePath[0] : '', // Handle null or empty array
        })
      );
      toast.success('Product added to cart successfully!');  // Show success toast when product is added
    }
  };
  

  return (
    <div className="flex w-full h-[600px]">
     <div className="w-1/2 flex justify-center items-center bg-[#EDEDED] rounded-l-lg overflow-hidden">
  {product.listImagePath && product.listImagePath.length > 0 ? (
    <img
      src={`https://storage.googleapis.com/awkat-o-salat/${product.listImagePath[0]}`}  // Correct reference to listImagePath
      alt={product.productName}
      className="object-cover h-full w-full"
    />
  ) : (
    <p>No image available</p>
  )}
</div>


      {/* Details Section */}
      <div className="w-1/2 p-6 flex flex-col justify-between">
        <h2 className="text-2xl font-bold text-[#273B60] mb-4">
          {product.productName}
        </h2>
        <p className="text-gray-600 mb-4">{product.description}</p>
        <div className="flex items-center mb-4">
          <span className="text-xl font-bold text-[#F16427] mr-2">
            ${product.discountedPrice.toFixed(2)}
          </span>
          {product.price !== product.discountedPrice && (
            <span className="text-sm line-through text-gray-500">
              ${product.price.toFixed(2)}
            </span>
          )}
        </div>

        {/* Quantity Section */}
        <div className="flex items-center mb-4">
          <button
            onClick={handleDecrement}
            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-l-lg p-2"
          >
            -
          </button>
          <input
            type="text"
            value={quantity}
            readOnly
            className="border-t border-b border-gray-300 text-center w-12"
          />
          <button
            onClick={handleIncrement}
            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-r-lg p-2"
          >
            +
          </button>
        </div>

        {/* Additional Details */}
        <ul className="text-sm text-gray-700 space-y-2">
          <li><strong>Status:</strong> {product.status}</li>
          <li><strong>Quantity:</strong> {product.quantity}</li>
          {/* <li><strong>Category:</strong> {product.tags.name}</li> */}
          <li><strong>Liked Count:</strong> {product.likedCount}</li>
          <li><strong>Purchased Count:</strong> {product.purchasedCount}</li>
          <li><strong>Tags:</strong> {product.tags.map(tag => tag.name).join(', ')}</li>
          <li><strong>Business:</strong> {product.business.businessInfo.name}</li>
          <li><strong>Created At:</strong> {new Date(product.createdAt).toLocaleDateString()}</li>
          <li><strong>Last Modified At:</strong> {new Date(product.lastModifiedAt).toLocaleDateString()}</li>
        </ul>

        {/* Add to Cart Button */}
        <button
          onClick={addToCartHandler}
          className="mt-4 bg-[#D1822D] text-white py-2 px-4 rounded"
        >
          Add to Cart
        </button>
      </div>

      {/* Toast Container for displaying alerts */}
      <ToastContainer />
    </div>
  );
};

export default Product;
