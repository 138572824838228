import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  userInfo: JSON.parse(localStorage.getItem('userData')) || {
    firstName: '',
    lastName: '',
    email: '',
  },
  accessToken: localStorage.getItem('token') || null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthorizedHandler: (state, action) => {
      state.isAuthenticated = action.payload;
      localStorage.setItem('isAuthenticated', action.payload);
    },
    setUserInfo: (state, action) => {
      const { firstName, lastName, email, access_token } = action.payload;

      state.userInfo = { firstName, lastName, email };
      state.accessToken = access_token;

      // Save to localStorage
      // localStorage.setItem('userData', JSON.stringify({ firstName, lastName, email }));
      localStorage.setItem('token', access_token);
    },
  },
});

// Exporting actions and selectors
export const { setAuthorizedHandler, setUserInfo } = userSlice.actions;
export const selectUserInfo = (state) => state.user.userInfo;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;

// Exporting reducer
export default userSlice.reducer;
