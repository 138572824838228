import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productTags: [],
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductTags: (state, action) => {
      console.log('Received product tags:', action.payload); // Log the incoming product tags
      return {
        ...state,
        productTags: action.payload,
      };
    },
  },
});

export const { setProductTags } = productSlice.actions;
export default productSlice.reducer;
