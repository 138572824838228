import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Location.css';
import client from '../../GraphQL/ApolloClient';
import { GET_PRODUCTS_GENERIC_SEARCH } from '../../GraphQL/mutation'; // Import the query


Modal.setAppElement('#root');



const LocationBar = ({ location, postalCode, onUpdateLocation, onUpdateProducts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempPostalCode, setTempPostalCode] = useState(postalCode);
  const [tempSelectedCountry, setTempSelectedCountry] = useState('');
  const [searchName, setSearchName] = useState('');
  const [latitude, setLatitude] = useState(null); 
  const [longitude, setLongitude] = useState(null); 

  const timeObj = {
    startTime: "2024-09-29T01:00:00.000+00:00",
    endTime: "2024-09-29T23:59:00.000+00:00"
  };

 
  useEffect(() => {
    setTempPostalCode(postalCode);
    fetchLatLong(postalCode, location); // Fetch coordinates of default location
  }, [postalCode, location]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const applyLocation = async () => {
    try {
      // If searchName is empty, clear products and update the location.
      if (!searchName.trim()) {
        onUpdateProducts(null); // Clear products if search is empty.
        
        if (typeof onUpdateLocation === 'function') {
          onUpdateLocation(tempSelectedCountry || location, tempPostalCode);
        }
  
        closeModal(); // Close the modal.
        return; // Stop further execution.
      }
  
      // Fetch coordinates and update state.
      await fetchLatLong(tempPostalCode, tempSelectedCountry || location);
  
      if (typeof onUpdateLocation === 'function') {
        onUpdateLocation(tempSelectedCountry || location, tempPostalCode);
      }
  
      // Perform product search if coordinates are available.
      if (latitude !== null && longitude !== null) {
        const response = await client.query({
          query: GET_PRODUCTS_GENERIC_SEARCH,
          variables: {
            searchName,
            timeObj,
            latitude,
            longitude,
            pageNo: 0,
            pageSize: 10,
          },
        });
  
        const products = response.data.getProductsGenericSearchKeyAuth.data.products;
        onUpdateProducts(products.length ? products : []);
      } else {
        console.warn('Coordinates not available, skipping API call.');
        onUpdateProducts([]);
      }
  
      closeModal(); // Close modal after everything is done.
  
    } catch (error) {
      console.error('Error applying location:', error);
      onUpdateProducts(undefined); // Handle error case.
    }
  };  

  // Fetch Latitude and Longitude using OpenCage API
  const fetchLatLong = async (postalCode, location) => {
    const apiKey = '63c4ee09e349437992c7a1aac53c7c7b'; 
    const query =  location;

    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          query
        )}&key=${apiKey}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry;
        console.log(`search api :Latitude: ${lat}, Longitude: ${lng}`);
        setLatitude(lat); 
        setLongitude(lng); 
      } else {
        console.error('No results found for the given location.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setTempPostalCode(value);
    }
  };

  const handleSearchChange = (e) => setSearchName(e.target.value);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      applyLocation();
    }
  };

  return (
    <div className="header-container">
      <div className="location" onClick={openModal}>
        📍 {location} {tempPostalCode}
      </div>

      <div className="search-bar">
        <input 
          type="text" 
          className="search-input" 
          placeholder="Search products at tajjam" 
          value={searchName}
          onChange={handleSearchChange} // Handle search input changes
          onKeyPress={handleKeyPress} // Handle key press event
        />
        <button className="search-button" onClick={applyLocation}>
          <span role="img" aria-label="search">🔍</span>
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <button className="close-button" onClick={closeModal}>
          ✖️ {/* You can use a different icon or text here */}
        </button>
        <h2>Choose your location</h2>
        <p>Delivery options and speeds may vary for different locations.</p>

        <div className="postal-input">
          <input
            type="text"
            placeholder="Enter a US zip code"
            value={tempPostalCode}
            onChange={handlePostalCodeChange}
          />
        </div>

        <p>or ship outside the US</p>

        <select
          value={tempSelectedCountry}
          onChange={(e) => setTempSelectedCountry(e.target.value)}
        >
          <option value="">Choose</option>
          <option value="United States">United States</option>
          <option value="Pakistan">Pakistan</option>
          <option value="Canada">Canada</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="Australia">Australia</option>
          <option value="India">India</option>
          <option value="Germany">Germany</option>
          <option value="France">France</option>
          <option value="Japan">Japan</option>
          <option value="China">China</option>
          <option value="Brazil">Brazil</option>
          <option value="South Africa">South Africa</option>
          <option value="Houston">Houston</option>
        </select>

        <button className="done-btn" onClick={applyLocation}>
          Done
        </button>
      </Modal>
    </div>
  );
};

export default LocationBar;
