import React, { useState } from 'react';
import { AiOutlineDollar } from 'react-icons/ai';
import { FaRegEye } from 'react-icons/fa';
import Modal from 'react-modal';
import ProductDetails from '../../components/ProductDetails';

// Set the modal's root element for accessibility
Modal.setAppElement('#root');

const ProductCard = ({ product, onAddToCart }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    console.log('Opening product:', product); 
    // Check if product is received correctly
    // console.log('jddk',product.slug);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const imagePath = product?.listImagePath?.[0]
    ? `https://storage.googleapis.com/awkat-o-salat/${product.listImagePath[0]}`
    : 'https://via.placeholder.com/300';

  return (
    <div className="prod-wrapper relative mx-4 mt-10 w-full xl:w-[23%] lg:w-[12%] md:w-[44%] sm:w-1/2">
      <div className="img flex h-80 justify-center items-center bg-[#EDEDED]">
        <img
          style={{ maxHeight: 300 }}
          src={imagePath}
          alt={product.description || 'No description available'}
        />
      </div>
      <h4 className="prod-title text-[20px] italic font-semibold mt-3 text-[#273B60]">
        {product.productName}
      </h4>
      <p className="prod-description text-[16px] text-[#273B60]">
        {product.description}
      </p>
      <div className="prod-info flex justify-between items-center">
        <div className="flex items-center">
          <AiOutlineDollar className="text-lg text-[#273B60]" />
          <h4 className="text-[20px] italic font-semibold mt-1 text-[#273B60] ml-2">
            ${product.discountedPrice}.00
          </h4>
        </div>
        <span className="line-through text-gray-500 text-sm mr-2">
          ${product.price}.00
        </span>
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onAddToCart(product);
        }}
        className="absolute top-5 right-2 bg-white text-xs font-bold py-2 px-3 rounded text-orange-700"
      >
        + Add
      </button>
      <button
        onClick={() => openModal(product)}
        className="absolute bottom-0 right-0 bg-white rounded-full p-2 text-orange-700"
      >
        <FaRegEye size={24} />
      </button>

      {/* Modal for Product Details */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Product Details"
        className="modal-content"
        overlayClassName="ReactModal__Overlay"
      >
        <button onClick={closeModal} className="modal-close-btn">
          &times;
        </button>

        {selectedProduct ? (
          <ProductDetails slug={selectedProduct.slug} />
        ) : (
          <p>Loading product details...</p>
        )}
      </Modal>
    </div>
  );
};

export default ProductCard;
