import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar'; // Replace with your Navbar component
import Footer from './Footer'; // Replace with your Footer component
import { GET_PRODUCT_BY_IDENTIFIER } from '../GraphQL/mutation';
import client from '../GraphQL/ApolloClient';
import ProductCard from './ProductCard';
import Loader from '../common/Loader/Loader';

const ProductDetails = ({ slug: propSlug }) => {
  const { slug: paramSlug } = useParams();
  const slug = propSlug || paramSlug;  // Use prop slug if available, otherwise fallback to slug from params
  const [products, setProducts] = useState({});
  const [loader, setLoader] = useState(true);

  async function fetchProductByIdentifier(identifier) {
    try {
      const response = await client.query({
        query: GET_PRODUCT_BY_IDENTIFIER,
        variables: { identifier },
      });

      const productData = response.data.getProductByIdentifier.data;
      console.log(productData);
      setProducts(productData);
      setLoader(false);
      return productData;
    } catch (error) {
      console.error('Error fetching product by identifier:', error);
      throw error; // Propagate the error if you want to handle it elsewhere
    }
  }

  useEffect(() => {
    console.log('slug on prod detail',slug);
    if (slug) {
      fetchProductByIdentifier(slug);
    }
  }, [slug]);

  return (
    <div className="font-poppins">
      <Helmet>
        <title>{products?.productName || 'Product Details'}</title>
        <meta
          name="description"
          content={products?.description || 'Product Details'}
        />
        <meta
          property="og:title"
          content={products?.productName || 'Product Details'}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={
            products?.listImagePath ||
            'https://storage.googleapis.com/awkat-o-salat/17212132243770.jpg'
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={products?.description || 'Product Details'}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="Alt text for image" />
      </Helmet>

    
      {loader && <Loader />}
      {!loader && <ProductCard productData={products} slug={slug} />}
    
    </div>
  );
};

export default ProductDetails;
