import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const ReturnPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto my-8 mt-[10rem] px-4 font-poppins">
        <h1 className="text-2xl font-semibold mb-4">
          Return and Refund Policy
        </h1>
        <p className="mb-4">
          At Tajjam, we are committed to providing our customers with
          high-quality grocery items. If you are not entirely satisfied with
          your purchase, we're here to help.
        </p>

        <h2 className="text-xl font-semibold mb-2">Return Policy</h2>
        <p className="mb-4">
          You have the right to return any item purchased from Tajjam within 14
          days of receiving your order. To be eligible for a return, your item
          must be unused and in the same condition that you received it. It must
          also be in the original packaging.
        </p>
        <p className="mb-4">
          To initiate a return, please contact our customer service team
          at&nbsp;
          <a
            href="mailto:contact@tajjam.com"
            className="text-blue-500 underline"
          >
            contact@tajjam.com
          </a>
          &nbsp;with your order details. We will provide you with a return
          authorization and instructions on how to return the items.
        </p>

        <h2 className="text-xl font-semibold mb-2">Non-Returnable Items</h2>
        <p className="mb-4">
          Certain items cannot be returned, including perishable goods such as
          fresh produce, dairy products, and any other items that are not
          suitable for return due to health protection or hygiene reasons once
          unsealed.
        </p>

        <h2 className="text-xl font-semibold mb-2">Refund Policy</h2>
        <p className="mb-4">
          Once we receive your returned item, we will inspect it and notify you
          of the status of your refund. If your return is approved, we will
          initiate a refund to you in the form of store credit or cash, as per
          your preference.
        </p>

        <h2 className="text-xl font-semibold mb-2">Shipping Costs</h2>
        <p className="mb-4">
          You will be responsible for paying for your own shipping costs for
          returning your item. Shipping costs are non-refundable. If you receive
          a refund, the cost of return shipping will be deducted from your
          refund.
        </p>

        <h2 className="text-xl font-semibold mb-2">
          Billing Terms and Conditions
        </h2>
        <p className="mb-4">
          By placing an order on Tajjam, you agree to pay all applicable fees
          and charges associated with your purchase, including any taxes,
          shipping fees, and other charges. We only accept cash on delivery as
          the payment method. You are responsible for providing accurate billing
          information and ensuring that you have the exact amount for payment at
          the time of delivery.
        </p>
        <p className="mb-4">
          Since we only accept cash on delivery, any issues related to payment
          will be resolved at the time of delivery. We do not accept
          credit/debit cards or digital payments at this time.
        </p>
        <p className="mb-4">
          We may offer promotional discounts or special offers from time to
          time. These offers are subject to specific terms and conditions and
          may be withdrawn at any time without notice.
        </p>
        <p className="mb-4">
          If you have any questions or concerns about our billing terms and
          conditions, please contact us at&nbsp;
          <a
            href="mailto:contact@tajjam.com"
            className="text-blue-500 underline"
          >
            contact@tajjam.com
          </a>
          .
        </p>

        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about our return and refund policy, please
          contact us at&nbsp;
          <a
            href="mailto:contact@tajjam.com"
            className="text-blue-500 underline"
          >
            contact@tajjam.com
          </a>
          . Our customer service team is available to assist you with any
          concerns.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default ReturnPolicy;
