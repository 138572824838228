import React from 'react';
import './styles.css'; // Import CSS file for styling

const Loader = ({ size = 50 }) => {
  return (
    <div className="loader-container">
      <div className="loader" style={{ width: size, height: size }}>
        <div className="inner" style={{ borderColor: '#F16427' }}></div>
        <div className="middle" style={{ borderColor: '#F16427' }}></div>
        <div className="outer" style={{ borderColor: '#F16427' }}></div>
      </div>
    </div>
  );
};

export default Loader;
