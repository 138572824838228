import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import CSS for react-tabs
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { GET_BUSINESS_BY_USER, GET_REPORTS } from '../../GraphQL/mutation';
import client from '../../GraphQL/ApolloClient';
import 'chart.js/auto';
import Loader from '../../common/Loader/Loader';
import Chart from 'react-google-charts';
import DataTable from './DataTable';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

function Analytics() {
  const [propertyId, setPropertyId] = useState('');
  const [totalUsers, setTotalUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(30);
  // const { businessInfo } = useSelector((state) => state.business);

  const handleInputChange = (e) => {
    setPropertyId(e.target.value);
  };

  const handleNumberOfDaysChange = (e) => {
    setNumberOfDays(parseInt(e.target.value));
  };
  const dateHandler = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - numberOfDays); // Use numberOfDays here
    const startDateString = startDate.toISOString().split('T')[0];
    const endDateString = endDate.toISOString().split('T')[0];
    return { startDateString, endDateString };
  };

  const applyQuery = async (metric) => {
    const { startDateString, endDateString } = dateHandler();
    const { data } = await client.query({
      query: GET_REPORTS,
      variables: {
        metrics: [metric],
        dimensions: ['Country'],
        reportType: 'BASIC',
        propertyId: propertyId,
        startDate: startDateString,
        endDate: endDateString,
      },
    });
    return data;
  };

  const parseData = (data) => {
    if (!data) {
      return [];
    }
    const updatedData = JSON.parse(data || []);
    return updatedData?.map(([country, value]) => ({
      country,
      value: parseInt(value),
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const totalUsersData = await applyQuery('totalUsers');
      const activeUsersData = await applyQuery('activeUsers');
      const newUsersData = await applyQuery('newUsers');
      setTotalUsers(parseData(totalUsersData.getReports.data));
      setActiveUsers(parseData(activeUsersData.getReports.data));
      setNewUsers(parseData(newUsersData.getReports.data));
      setError(null);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
    }
  };

  const getChartData = (users) => {
    const data = users.map(({ country, value }) => [country, value]);
    data.unshift(['Country', 'Users']);
    return data;
  };

  const options = {
    // region: "002", // Africa
    // colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
    // backgroundColor: "#81d4fa",
    // datalessRegionColor: "#f8bbd0",
    // defaultColor: "#f5f5f5",
  };

  const columns = [
    {
      Header: 'Country',
      accessor: 'Country',
    },
    {
      Header: 'Users',
      accessor: 'Users',
    },
  ];

  const getTableData = (users) => {
    return getChartData(users)
      .slice(1)
      .map(([country, users]) => ({
        Country: country,
        Users: users,
      }));
  };

  const totalUserCount = (arr) => {
    return arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0,
    );
  };
  return (
    <>
      <Navbar />
      {loading && <Loader />}
      <div
        className="container mx-auto my-8 mt-[10rem] px-4 font-poppins"
        style={{ marginTop: 50 }}
      >
        <div className="input-container flex items-center mt-4">
          <div className="flex flex-col mr-4">
            <label htmlFor="propertyId" className="text-lg text-gray-600 mb-1">
              Property ID
            </label>
            <input
              id="propertyId"
              type="text"
              placeholder="Enter Property ID"
              value={propertyId}
              onChange={handleInputChange}
              className="bg-white focus:outline-none focus:ring-2 focus:ring-[#F16427] border border-[#F16427] rounded-md py-2 px-4 shadow-md text-[#F16427] text-xl cursor-pointer hover:text-blue-700 mb-2"
            />
          </div>
          <div className="flex flex-col mr-4">
            <label
              htmlFor="numberOfDays"
              className="text-lg text-gray-600 mb-1"
            >
              Number of Days
            </label>
            <input
              id="numberOfDays"
              type="number"
              placeholder="Number of days"
              value={numberOfDays}
              onChange={handleNumberOfDaysChange}
              className="bg-white focus:outline-none focus:ring-2 focus:ring-[#F16427] border border-[#F16427] rounded-md py-2 px-4 shadow-md text-[#F16427] text-xl cursor-pointer hover:text-blue-700 mb-2"
            />
          </div>
          <button
            onClick={fetchData}
            className="bg-[#F16427] hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded ml-4 mt-6" // Added ml-4 class for left margin
          >
            Fetch Reports
          </button>
        </div>

        {/* {loading && <Loader size={50} /> } */}
        <div className="chart-row" style={{ marginTop: 80, width: '50%' }}>
          <Tabs
            selectedIndex={selectedTab}
            onSelect={(index) => setSelectedTab(index)}
          >
            <TabList>
              <Tab>Total Users ({totalUserCount(totalUsers)})</Tab>
              <Tab>Active Users ({totalUserCount(activeUsers)})</Tab>
              <Tab>New Users ({totalUserCount(newUsers)})</Tab>
            </TabList>
            <TabPanel style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '100%', height: '400px' }}>
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={getChartData(totalUsers)}
                  options={options}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <DataTable columns={columns} data={getTableData(totalUsers)} />
              </div>
            </TabPanel>
            <TabPanel style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '100%', height: '400px' }}>
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={getChartData(activeUsers)}
                  options={options}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <DataTable columns={columns} data={getTableData(activeUsers)} />
              </div>
            </TabPanel>

            <TabPanel style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '100%', height: '400px' }}>
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={getChartData(newUsers)}
                  options={options}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <DataTable columns={columns} data={getTableData(newUsers)} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Analytics;
