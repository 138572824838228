import React, { useState } from 'react';
import Logo from '../../constants/svg/Logo';
import { useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION } from '../../GraphQL/mutation';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Toaster from '../../common/Toaster';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../store/reducers/user';

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({
    status: false,
    type: '',
    message: '',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = 'First Name is required.';
    if (!lastName) newErrors.lastName = 'Last Name is required.';
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!password) {
      newErrors.password = 'Password is required.';
    } else if (password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long.';
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }
    if (!termsAccepted) {
      newErrors.termsAccepted = 'You must accept the terms and conditions.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [createUserMutation] = useMutation(CREATE_USER_MUTATION);
  const createUser = async (userName, email, password, fName, lName) => {
    try {
      const userData = {
        userName,
        email,
        password,
        name: { firstName: fName, lastName: lName },
      };

      const response = await createUserMutation({
        variables: { userData },
      });

      if (response && response.data && response.data.createUser) {
        const userId = response.data.createUser.data.userId;
        const username = userData.userName;

        // Dispatch user data to Redux store
        const userToSave = { firstName, lastName, email };
        dispatch(setUserInfo(userToSave));

        // Save userId to local storage
        localStorage.setItem('userId', userId);
        localStorage.setItem('username', username);
        localStorage.setItem('userData', JSON.stringify(userToSave));

        const redirectPath = location.state?.from || '/login';
        navigate(redirectPath);
        return 'Created';
      } else {
        setError({
          status: true,
          type: 'error',
          message: 'Unexpected response structure',
        });
        return 'Unexpected response structure';
      }
    } catch (error) {
      setError({
        status: true,
        type: 'error',
        message: 'Something went wrong, please try again',
      });
      return 'Something went wrong, please try again';
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      await createUser('', email, password, firstName, lastName);
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-white mx-auto my-8 mt-[10rem] px-4 font-poppins">
      {error.status && <Toaster {...error} changeError={setError} />}
      <Logo className="mx-auto mb-4" width="1750" height="140" />
      <h4 className="text-center mb-5 text-xl text-F16427" style={{ marginLeft: 200 }}>
        Shopping Made Easy
      </h4>
      <h3 className="text-center mb-5 text-xl text-273B60">
        Sign up for an account and get access <br /> to every store.
      </h3>

      <div className="form-container w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        <h4 className="text-center mb-8 text-xl text-273B60">Sign Up</h4>

        <form onSubmit={handleSubmit} className="w-full">
          {/* Form Fields */}
          {/* First Name */}
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First Name *
            </label>
            <input
              type="text"
              id="firstName"
              className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${errors.firstName ? 'border-red-500' : ''}`}
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
          </div>
          {/* Last Name */}
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name *
            </label>
            <input
              type="text"
              id="lastName"
              className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${errors.lastName ? 'border-red-500' : ''}`}
              placeholder="Enter your last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
          </div>
          {/* Email */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email *
            </label>
            <input
              type="email"
              id="email"
              className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${errors.email ? 'border-red-500' : ''}`}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          {/* Password */}
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              New Password *
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${errors.password ? 'border-red-500' : ''}`}
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-2"
                onClick={handlePasswordToggle}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
          </div>
          {/* Confirm Password */}
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
              Confirm Password *
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${errors.confirmPassword ? 'border-red-500' : ''}`}
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>}
          </div>
          {/* Terms and Conditions */}
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="terms"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
            <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
              I accept the terms and conditions *
            </label>
            {errors.termsAccepted && <p className="text-red-500 text-sm mt-1">{errors.termsAccepted}</p>}
          </div>
          {/* Submit Button */}
          <button
            type="submit"
                 className="w-full py-2 rounded-lg bg-[#F16427] text-white font-medium hover:bg-[#C6471A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F16427]"
          >
            Sign Up
          </button>
        </form>
        {/* Alternate Links */}
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="text-F16427 hover:underline">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
