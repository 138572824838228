const Logo = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 951.524 307.994"
    >
      <g
        id="Group_18464"
        data-name="Group 18464"
        transform="translate(-45.512 -121)"
      >
        <ellipse
          id="Ellipse_116"
          data-name="Ellipse 116"
          cx="141.793"
          cy="9.604"
          rx="141.793"
          ry="9.604"
          transform="translate(45.514 409.787)"
          opacity="0.15"
        />
        <path
          id="Path_11492"
          data-name="Path 11492"
          d="M1104.014,800.636c0,.714-.021,1.428-.084,2.122v.023a26.381,26.381,0,0,1-.738,4.351,24.05,24.05,0,0,1-2.426,5.979v.021a24.729,24.729,0,0,1-4.157,5.5c-.152.149-.3.3-.478.454l-.669.627a23.607,23.607,0,0,1-15.68,5.956H885.538c-13.384,0-24.234-11.218-24.234-25.035l11.932-200.653c0-13.818,10.85-25.036,24.235-25.036h170.376c13.384,0,24.234,11.218,24.234,25.036l6,101.03.434,7.038,1.47,24.842,2.362,39.7.433,7.146.412,7.06Z"
          transform="translate(-795.157 -406.281)"
          fill="#f16427"
        />
        <path
          id="Path_11493"
          data-name="Path 11493"
          d="M896.378,825.671h-10.84c-13.384,0-24.232-11.205-24.232-25.036l11.921-200.654c0-13.828,10.848-25.035,24.233-25.035H908.3c-13.384,0-24.232,11.208-24.232,25.035L872.143,800.635C872.143,814.465,882.991,825.671,896.378,825.671Z"
          transform="translate(-795.154 -406.282)"
          fill="#d65227"
        />
        <g
          id="Group_3334"
          data-name="Group 3334"
          transform="translate(127.316 262.891)"
        >
          <g
            id="Group_3332"
            data-name="Group 3332"
            transform="translate(43.717 80.583)"
          >
            <path
              id="Path_11494"
              data-name="Path 11494"
              d="M919.068,657.66A8.137,8.137,0,1,0,927.2,665.8,8.137,8.137,0,0,0,919.068,657.66Z"
              transform="translate(-910.932 -657.66)"
              fill="#fff"
            />
            <path
              id="Path_11495"
              data-name="Path 11495"
              d="M930.763,657.66A8.137,8.137,0,1,0,938.9,665.8,8.137,8.137,0,0,0,930.763,657.66Z"
              transform="translate(-897.91 -657.66)"
              fill="#fff"
            />
          </g>
          <g id="Group_3333" data-name="Group 3333">
            <path
              id="Path_11496"
              data-name="Path 11496"
              d="M990.83,630.877a6.074,6.074,0,0,0-5.835,4.5,39.493,39.493,0,0,1-76.086,0,6.074,6.074,0,0,0-5.834-4.5h0a6.088,6.088,0,0,0-5.863,7.7,51.614,51.614,0,0,0,99.484,0A6.09,6.09,0,0,0,990.83,630.877Z"
              transform="translate(-882.739 -606.898)"
              fill="#fff"
            />
            <path
              id="Path_11497"
              data-name="Path 11497"
              d="M947.694,619.531a9.108,9.108,0,1,0,9.106,9.109A9.11,9.11,0,0,0,947.694,619.531Z"
              transform="translate(-836.425 -619.531)"
              fill="#fff"
            />
            <path
              id="Path_11498"
              data-name="Path 11498"
              d="M909.657,629.365a9.084,9.084,0,0,1,5.985,3.147,4.055,4.055,0,0,0,3.686,1.5,4.774,4.774,0,0,0,4.035-4.246,4.63,4.63,0,0,0-1.2-3.642,18.588,18.588,0,0,0-31.907,11.923,4.628,4.628,0,0,0,1.389,3.569,4.732,4.732,0,0,0,5.8.716,4.053,4.053,0,0,0,1.9-3.491,9.266,9.266,0,0,1,.836-3.684l0-.007c.055-.116.109-.233.169-.349l.024-.046a9.058,9.058,0,0,1,6.992-5.348A8.959,8.959,0,0,1,909.657,629.365Z"
              transform="translate(-890.246 -618.781)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Path_11499"
          data-name="Path 11499"
          d="M894.179,604.16c4.208-15.325,18.512-51.767,59.554-51.767,40.963,0,52.73,36.116,55.9,51.715a8.212,8.212,0,1,1-8.123,1.606c-2.583-13.57-12.384-44.955-47.776-44.955-34.886,0-47.463,31.131-51.317,44.9a8.187,8.187,0,1,1-8.237-1.5Z"
          transform="translate(-764.488 -431.393)"
          fill="#fdce0c"
        />
        <path
          id="Path_11511"
          data-name="Path 11511"
          d="M1018.729,627.376h5.447a8.476,8.476,0,0,1,6.276,2.348,9.573,9.573,0,0,1,0,12.554,8.216,8.216,0,0,1-6.276,2.5h-5.447v40.236a11.779,11.779,0,0,0,.831,4.842,6.925,6.925,0,0,0,2.346,2.872,9.994,9.994,0,0,0,3.327,1.516,23.331,23.331,0,0,0,3.935.6,7.373,7.373,0,0,1,5.368,2.648,9.711,9.711,0,0,1,1.891,6.277,7.278,7.278,0,0,1-2.572,5.824q-2.571,2.187-7.561,2.192a30.913,30.913,0,0,1-9.834-1.515,20.866,20.866,0,0,1-7.939-4.762,21.8,21.8,0,0,1-5.294-8.321,34.306,34.306,0,0,1-1.892-12.025V644.774a7.765,7.765,0,0,1-5.37-2.876,9.038,9.038,0,0,1-1.889-5.748,9.2,9.2,0,0,1,1.889-5.973,7.381,7.381,0,0,1,5.37-2.648V601.962a8.377,8.377,0,0,1,2.5-6.425,8.636,8.636,0,0,1,14.9,6.425Z"
          transform="translate(-647.326 -385.97)"
          fill="#273b60"
        />
        <path
          id="Path_11512"
          data-name="Path 11512"
          d="M1102.135,686.433a8.107,8.107,0,0,1-8.773,8.773,8.606,8.606,0,0,1-6.127-2.344,8.4,8.4,0,0,1-2.5-6.429v-.452a42.036,42.036,0,0,1-4.913,4.157,33.724,33.724,0,0,1-6.2,3.553,41.53,41.53,0,0,1-7.185,2.424,32.371,32.371,0,0,1-7.564.9,45.112,45.112,0,0,1-17.85-3.477,42.658,42.658,0,0,1-23.294-23.523,46.716,46.716,0,0,1,0-34.639,43.329,43.329,0,0,1,9.3-14.145,44.781,44.781,0,0,1,14.144-9.606,43.864,43.864,0,0,1,17.7-3.552,36.723,36.723,0,0,1,14.976,2.873,31.814,31.814,0,0,1,10.89,7.716,8.216,8.216,0,0,1,2.5-6.28,9.584,9.584,0,0,1,12.555,0,8.5,8.5,0,0,1,2.345,6.28Zm-43.263-60.962a26.563,26.563,0,0,0-19.211,7.945,27.735,27.735,0,0,0-5.748,8.7,26.773,26.773,0,0,0-2.119,10.586,26.257,26.257,0,0,0,2.119,10.517,26.811,26.811,0,0,0,14.294,14.293,26.657,26.657,0,0,0,10.664,2.117,25.152,25.152,0,0,0,18.381-7.865,27.036,27.036,0,0,0,5.37-8.544,28.114,28.114,0,0,0,1.965-10.517,28.671,28.671,0,0,0-1.965-10.586,27.73,27.73,0,0,0-5.37-8.7,24.446,24.446,0,0,0-8.17-5.825A24.825,24.825,0,0,0,1058.872,625.471Z"
          transform="translate(-624.695 -369.392)"
          fill="#273b60"
        />
        <path
          id="Path_11513"
          data-name="Path 11513"
          d="M1071.177,619.212a8.888,8.888,0,0,1,14.9-6.431,8.4,8.4,0,0,1,2.5,6.431v84.709a34.444,34.444,0,0,1-1.891,12.025,21.891,21.891,0,0,1-5.294,8.321,20.992,20.992,0,0,1-7.943,4.766,30.91,30.91,0,0,1-9.831,1.511q-4.992,0-7.565-2.192a7.285,7.285,0,0,1-2.57-5.825,9.718,9.718,0,0,1,1.89-6.277,7.367,7.367,0,0,1,5.37-2.648,24.91,24.91,0,0,0,3.858-.6,9.9,9.9,0,0,0,3.4-1.516,6.962,6.962,0,0,0,2.346-2.871,11.83,11.83,0,0,0,.833-4.843Z"
          transform="translate(-581.183 -366.761)"
          fill="#273b60"
        />
        <path
          id="Path_11514"
          data-name="Path 11514"
          d="M1163.331,686.433a8.106,8.106,0,0,1-8.773,8.773,8.615,8.615,0,0,1-6.129-2.344,8.4,8.4,0,0,1-2.494-6.429v-.452a42.043,42.043,0,0,1-4.916,4.157,33.615,33.615,0,0,1-6.2,3.553,41.521,41.521,0,0,1-7.185,2.424,32.363,32.363,0,0,1-7.562.9,45.1,45.1,0,0,1-17.85-3.477,42.642,42.642,0,0,1-23.294-23.523,46.723,46.723,0,0,1,0-34.639,43.279,43.279,0,0,1,9.3-14.145,44.765,44.765,0,0,1,14.146-9.606,43.866,43.866,0,0,1,17.7-3.552,36.7,36.7,0,0,1,14.973,2.873,31.817,31.817,0,0,1,10.893,7.716,8.214,8.214,0,0,1,2.494-6.28,9.589,9.589,0,0,1,12.558,0,8.509,8.509,0,0,1,2.344,6.28Zm-43.262-60.962a26.568,26.568,0,0,0-19.215,7.945,27.78,27.78,0,0,0-5.746,8.7,26.791,26.791,0,0,0-2.12,10.586,26.274,26.274,0,0,0,2.12,10.517,26.8,26.8,0,0,0,14.293,14.293,26.662,26.662,0,0,0,10.667,2.117,25.15,25.15,0,0,0,18.378-7.865,27.036,27.036,0,0,0,5.37-8.544,28.125,28.125,0,0,0,1.968-10.517,28.683,28.683,0,0,0-1.968-10.586,27.73,27.73,0,0,0-5.37-8.7,24.449,24.449,0,0,0-8.17-5.825A24.824,24.824,0,0,0,1120.069,625.471Z"
          transform="translate(-556.556 -369.392)"
          fill="#273b60"
        />
        <path
          id="Path_11515"
          data-name="Path 11515"
          d="M1141.374,686.586a8.451,8.451,0,0,1-2.342,6.353,9.824,9.824,0,0,1-12.559,0,8.162,8.162,0,0,1-2.493-6.353V617.607a7.631,7.631,0,0,1,1.966-5.524,7,7,0,0,1,5.294-2.037q7.412,0,8.623,6.957a20.08,20.08,0,0,1,8.7-6.81,28.171,28.171,0,0,1,10.666-2.115,37.776,37.776,0,0,1,7.411.753,34.483,34.483,0,0,1,7.188,2.271,26.365,26.365,0,0,1,6.352,4.01,22.818,22.818,0,0,1,4.915,5.82,25.1,25.1,0,0,1,11.646-9.83,37.493,37.493,0,0,1,14.521-3.024,31.633,31.633,0,0,1,12.634,2.644,35.213,35.213,0,0,1,10.812,7.261,35.827,35.827,0,0,1,7.567,11.045,33.933,33.933,0,0,1,2.873,13.989v43.568a8.437,8.437,0,0,1-2.344,6.353,9.718,9.718,0,0,1-12.479,0,8.286,8.286,0,0,1-2.421-6.353V643.017a17.511,17.511,0,0,0-1.361-6.96,18.378,18.378,0,0,0-3.707-5.593,17.361,17.361,0,0,0-5.37-3.784,15,15,0,0,0-6.2-1.361,16.406,16.406,0,0,0-6.5,1.361,19.936,19.936,0,0,0-9.908,9.377,15.37,15.37,0,0,0-1.589,6.96v43.568a8.445,8.445,0,0,1-2.344,6.353,8.873,8.873,0,0,1-6.429,2.268,8.52,8.52,0,0,1-6.051-2.268,8.3,8.3,0,0,1-2.42-6.353V643.017a16.716,16.716,0,0,0-1.437-6.96,19.647,19.647,0,0,0-3.781-5.593,17.405,17.405,0,0,0-5.37-3.784,15.019,15.019,0,0,0-6.2-1.361,16.611,16.611,0,0,0-6.431,1.361,18.434,18.434,0,0,0-5.748,3.784,20.585,20.585,0,0,0-4.083,5.593,15.364,15.364,0,0,0-1.588,6.96Z"
          transform="translate(-502.684 -369.392)"
          fill="#273b60"
        />
        <path
          id="Path_11516"
          data-name="Path 11516"
          d="M1205.718,686.586a8.445,8.445,0,0,1-2.344,6.353,9.82,9.82,0,0,1-12.556,0,8.15,8.15,0,0,1-2.5-6.353V617.607a7.625,7.625,0,0,1,1.967-5.524,7,7,0,0,1,5.295-2.037q7.408,0,8.62,6.957a20.08,20.08,0,0,1,8.7-6.81,28.19,28.19,0,0,1,10.667-2.115,37.77,37.77,0,0,1,7.412.753,34.484,34.484,0,0,1,7.187,2.271,26.285,26.285,0,0,1,6.353,4.01,22.78,22.78,0,0,1,4.915,5.82,25.1,25.1,0,0,1,11.647-9.83,37.488,37.488,0,0,1,14.521-3.024,31.618,31.618,0,0,1,12.632,2.644,35.2,35.2,0,0,1,10.815,7.261,35.824,35.824,0,0,1,7.566,11.045,33.947,33.947,0,0,1,2.871,13.989v43.568a8.444,8.444,0,0,1-2.342,6.353,9.722,9.722,0,0,1-12.482,0,8.3,8.3,0,0,1-2.417-6.353V643.017a17.5,17.5,0,0,0-1.364-6.96,18.4,18.4,0,0,0-3.7-5.593,17.394,17.394,0,0,0-5.371-3.784,15.012,15.012,0,0,0-6.2-1.361,16.4,16.4,0,0,0-6.5,1.361,19.952,19.952,0,0,0-9.909,9.377,15.388,15.388,0,0,0-1.587,6.96v43.568a8.447,8.447,0,0,1-2.344,6.353,8.873,8.873,0,0,1-6.429,2.268,8.514,8.514,0,0,1-6.05-2.268,8.29,8.29,0,0,1-2.419-6.353V643.017a16.664,16.664,0,0,0-1.44-6.96,19.555,19.555,0,0,0-3.78-5.593,17.358,17.358,0,0,0-5.371-3.784,15,15,0,0,0-6.2-1.361,16.6,16.6,0,0,0-6.429,1.361,18.376,18.376,0,0,0-5.748,3.784,20.5,20.5,0,0,0-4.083,5.593,15.327,15.327,0,0,0-1.59,6.96Z"
          transform="translate(-431.042 -369.392)"
          fill="#273b60"
        />
        <path
          id="Path_11517"
          data-name="Path 11517"
          d="M1316.956,694.17q-7.414,0-8.772-7.564a21.245,21.245,0,0,1-8.168,6.958,27.134,27.134,0,0,1-12.253,2.572,37.65,37.65,0,0,1-14.671-2.724,31.64,31.64,0,0,1-11.045-7.562,32.958,32.958,0,0,1-6.96-11.645,44.479,44.479,0,0,1-2.418-14.978v-41.6a8.427,8.427,0,0,1,2.345-6.354,9.723,9.723,0,0,1,12.48,0,8.3,8.3,0,0,1,2.418,6.354v41.6q0,8.927,4.767,14.3t13.991,5.366q9.226,0,13.767-5.29t4.537-14.373v-41.6a8.424,8.424,0,0,1,2.343-6.354,9.825,9.825,0,0,1,12.557,0,8.156,8.156,0,0,1,2.5,6.354v68.976a7.176,7.176,0,0,1-2.193,5.52A7.4,7.4,0,0,1,1316.956,694.17Z"
          transform="translate(-359.399 -368.355)"
          fill="#273b60"
        />
        <path
          id="Path_11518"
          data-name="Path 11518"
          d="M1310.932,703.011a8.886,8.886,0,0,1-14.9,6.429,8.39,8.39,0,0,1-2.5-6.429v-101.2a8.19,8.19,0,0,1,2.5-6.277,9.56,9.56,0,0,1,12.554,0,8.476,8.476,0,0,1,2.345,6.277Z"
          transform="translate(-313.895 -385.97)"
          fill="#273b60"
        />
        <circle
          id="Ellipse_117"
          data-name="Ellipse 117"
          cx="8.773"
          cy="8.773"
          r="8.773"
          transform="translate(489.843 219.255)"
          fill="#273b60"
        />
        <ellipse
          id="Ellipse_116-2"
          data-name="Ellipse 116"
          cx="141.793"
          cy="9.604"
          rx="141.793"
          ry="9.604"
          transform="translate(45.512 409.787)"
          opacity="0.15"
        />
        <circle
          id="Ellipse_117-2"
          data-name="Ellipse 117"
          cx="8.773"
          cy="8.773"
          r="8.773"
          transform="translate(489.843 219.255)"
          fill="#273b60"
        />
      </g>
    </svg>
  );
};

export default Logo;
