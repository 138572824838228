import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PolicyBusiness from '../../components/PolicyBusiness';
import DeleteAccountPrivacy from '../../components/DeleteAccountPrivacy';
import { useAuth } from '../../auth/authContext';
import Analytics from '../../business/analytics';
import Features from '../../business/features';

const BusinessWebsite = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/features" element={<Features />} />
        </>
      ) : (
        <>
          <Route path="/privacy-policy" element={<PolicyBusiness />} />
          <Route
            path="/delete-account-privacy"
            element={<DeleteAccountPrivacy />}
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
};

export default BusinessWebsite;
