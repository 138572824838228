import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_BUSINESS_TIMINGS } from '../../GraphQL/mutation'; // Import the mutation
import { useNavigate } from 'react-router-dom';


const BusinessHours = ({ businessId, onClose = () => {} }) => {
  const [hours, setHours] = useState({
    Monday: [{ open: "00:00", close: "23:59" }],
    Tuesday: [{ open: "00:00", close: "23:59" }],
    Wednesday: [{ open: "00:00", close: "23:59" }],
    Thursday: [{ open: "00:00", close: "23:59" }],
    Friday: [{ open: "00:00", close: "23:59" }],
    Saturday: [{ open: "00:00", close: "23:59" }],
    Sunday: [{ open: "00:00", close: "23:59" }],
  });
  const navigate = useNavigate();
  const [enabledDays, setEnabledDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });
businessId="6730cbc5b88fd57797d4e3db";
  const [updateBusinessTimings] = useMutation(UPDATE_BUSINESS_TIMINGS);

  const toggleDay = (day) => {
    setEnabledDays((prev) => {
      const newState = { ...prev, [day]: !prev[day] };
      console.log("Updated enabledDays:", newState); // Debug log
      return newState;
    });
  };

  const addTimeRange = (day) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: [...prevHours[day], { open: "00:00", close: "23:59" }],
    }));
  };

  const removeTimeRange = (day, index) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: prevHours[day].filter((_, i) => i !== index),
    }));
  };

  const handleTimeChange = (day, index, type, value) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: prevHours[day].map((timeRange, i) =>
        i === index ? { ...timeRange, [type]: value } : timeRange
      ),
    }));
  };

  const createPayload = () => {
    const convertToISO = (time) => {
      const date = new Date();
      const [hours, minutes] = time.split(":");
      date.setUTCHours(parseInt(hours), parseInt(minutes), 0, 0);
      return date.toISOString();
    };
  
    const timings = {};
  
    // Loop through each day of the week and create the timings object
    Object.keys(hours).forEach((day) => {
      if (enabledDays[day]) {
        // Convert day to lowercase and assign timings correctly
        timings[day.toLowerCase()] = hours[day].map((range) => ({
          startTime: convertToISO(range.open),
          endTime: convertToISO(range.close),
        }));
      } else {
        timings[day.toLowerCase()] = []; // Empty array if the day is not enabled
      }
    });
  
    console.log("Generated Timings Payload:", timings); // Debug log for timings
  
    return {
      businessId, 
      input: { 
        timings, 
      },
    };
  };
  

  const handleSave = async () => {
    const payload = createPayload();
    console.log('Payload:', JSON.stringify(payload, null, 2));
  
    try {
      const response = await updateBusinessTimings({
        variables: {
          businessId,
          input: payload.input, // Ensure input is passed correctly
        },
      });
  
      // Log the response for debugging
      console.log("Mutation Response:", response);
  
      if (response.data.updateBusinessTimings.status === 200) {
        // Success response
        const userChoice = window.confirm(
          'Business hours updated successfully!\n\nWould you like to:\n\n1. Add Product\n2. Add Service\n\nClick OK for Product, Cancel for Service'
        );
      
        if (userChoice) {
          // If user clicks OK, navigate to Add Product
          navigate(`/business/addproduct/${businessId}`);
        } else {
          // If user clicks Cancel, navigate to Add Service
          navigate(`/business/addservice/${businessId}`);
        }
        onClose();
      } else {
        // Handle the case where status is not 200
        alert('Error updating business hours');
      }
      
  
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error updating business hours:', error);
      alert('Error updating business hours');
    }
  };
  

  return (
    <div style={styles.modal}>
      <h2 style={styles.modalTitle}>Select your business hours</h2>
      <div style={styles.headerRow}>
        <span style={styles.headerText}>Days</span>
        <span style={styles.headerText}>Opening Hours</span>
        <span style={styles.headerText}>Closing Hours</span>
      </div>
      {Object.keys(hours).map((day) => (
        <div key={day} style={styles.daySection}>
          <div style={styles.row}>
            <div style={styles.dayCheckbox}>
              <input
                type="checkbox"
                checked={enabledDays[day]}
                onChange={() => toggleDay(day)}
                style={styles.checkbox}
              />
              <span style={styles.dayText}>{day}</span>
            </div>
            <button style={styles.plusButton} onClick={() => addTimeRange(day)}>
              +
            </button>
          </div>
          {hours[day].map((timeRange, index) => (
            <div key={index} style={styles.timeRangeRow}>
              <input
                type="time"
                value={timeRange.open}
                onChange={(e) => handleTimeChange(day, index, "open", e.target.value)}
                style={styles.timeInput}
              />
              <input
                type="time"
                value={timeRange.close}
                onChange={(e) => handleTimeChange(day, index, "close", e.target.value)}
                style={styles.timeInput}
              />
              {index > 0 && (
                <button
                  style={styles.minusButton}
                  onClick={() => removeTimeRange(day, index)}
                >
                  -
                </button>
              )}
            </div>
          ))}
        </div>
      ))}
      <div style={styles.btn}>
        <button style={styles.okButton} onClick={handleSave}>
          OK
        </button>
      </div>
    </div>
  );
};

const styles = {
  btn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '10px',
    width: '90%',
    maxWidth: '400px',
    margin: 'auto',
    marginTop: '100px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
     maxHeight: '80vh', // Maximum height of modal
    overflowY: 'auto'
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#1f1f2e',
    textAlign: 'center',
    marginBottom: '20px',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    borderBottom: '1px solid #ccc',
    marginBottom: '10px',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#1f1f2e',
  },
  daySection: {
    marginBottom: '10px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
  },
  dayCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: '10px',
  },
  dayText: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 'bold',
  },
  timeRangeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 0',
  },
  timeInput: {
    fontSize: '14px',
    padding: '5px',
    margin: '0 5px',
    flex: 1,
  },
  plusButton: {
    fontSize: '16px',
    color: '#ff6b00',
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  minusButton: {
    fontSize: '16px',
    color: '#ff6b00',
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  okButton: {
    backgroundColor: '#ff6b00',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '10px',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default BusinessHours;
