import React, { useState, useEffect } from 'react';
import './style.css';
import { useQuery } from '@apollo/client';
import Navbar from '../../components/Navbar';
import { CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY } from '../../GraphQL/mutation';
import ServiceCard from '../../components/ServiceCard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocationBar from './Location';
import client from '../../GraphQL/ApolloClient';
import Loader from '../../common/Loader/Loader';


const DEFAULT_LATITUDE = 24.850707597571677;
const DEFAULT_LONGITUDE = 67.2693574847137;

const Index = () => {
  const [services, setServices] = useState([]);
  // const [filteredProducts, setFilteredProducts] = useState(null);
  const [filteredServices, setFilteredServices] = useState(null);
  const [latitude, setLatitude] = useState(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState(DEFAULT_LONGITUDE);
  const [location, setLocation] = useState('Current Location');
  const [postalCode, setPostalCode] = useState('');
  // const [locationLoaded, setLocationLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  // const [latitude, setLatitude] = useState(DEFAULT_LATITUDE);
  // const [longitude, setLongitude] = useState(DEFAULT_LONGITUDE);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState(null);
  // const [location, setLocation] = useState('Current Location'); 
  // const [postalCode, setPostalCode] = useState(''); 
  const updateLocation = (newLocation, newPostalCode) => {
    setLocation(newLocation);
    setPostalCode(newPostalCode);
  };

  const handleUpdateServices = (updatedServices) => {
    console.log('Updated services:', updatedServices);
    setFilteredServices(updatedServices );
  };

  const fetchLocationData = async (lat, lon) => {
    const API_KEY = '63c4ee09e349437992c7a1aac53c7c7b';
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lon}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results.length > 0) {
        const { city, postcode } = data.results[0].components;
        setLocation(city || 'Unknown Location');
        setPostalCode(postcode || '');
      }
    } catch (error) {
      console.log('Error fetching location data:', error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          fetchLocationData(position.coords.latitude, position.coords.longitude);
        },
        (error) => console.log('Error fetching location:', error.message)
      );
    }
  }, []);

  // const { loading, error, data } = useQuery(CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY, {
  //   variables: {
  //     latitude,
  //     longitude,
  //     pageNo: 0,
  //     pageSize: 10,
  //   },
  //   skip: !locationLoaded,
  //   onCompleted: () => {
  //     console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  //   }
  // });

  // Extract services from the query response
  //   const services = data?.calculateBusinessesWithinRangeByLocation?.data?.businesses
  //     ?.flatMap(business => business.businessDetails.servicesList) || [];
  //  console.log(services);
  //  console.log(latitude,longitude);
  const fetchBusinessesWithinRange = async () => {
    setLoading(true);
    try {
      const response = await client.query({
        query: CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY,
        variables: { latitude, longitude, pageNo, pageSize },
      });

      console.log('Response:', response); // Debugging log

      const businesses = response?.data?.calculateBusinessesWithinRangeByLocation?.data?.businesses || [];

      const allServices = businesses.flatMap((business) =>
        business?.businessDetails?.servicesList?.map((service) => ({
          ...service,
          businessId: business.businessId,
          businessName: business?.businessInfo?.name || 'Unnamed Business',
        })) || []
      );

      setServices(allServices);
      console.log('All Services:', allServices); // Debugging log
    } catch (error) {
      console.error('Error fetching businesses:', error);
      toast.error('Failed to fetch services. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    // Fetch services whenever latitude, longitude, pageNo, or pageSize changes
    if (latitude && longitude) {
      fetchBusinessesWithinRange();
    }
  }, [latitude, longitude, pageNo, pageSize]);


  const servicesToDisplay = filteredServices !== null ? filteredServices : services;
  return (

<>
  <Navbar />
  <div className="font-poppins container m-auto py-[6rem]">
    <div className="mb-6" style={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
      <LocationBar 
        onUpdateServices={handleUpdateServices} 
        location={location} 
        postalCode={postalCode} 
        onUpdateLocation={updateLocation} 
      />
    </div>

    {loading ? (
      <Loader />
    ) : (
      <div className="prod-loop flex flex-wrap justify-center items-center">
        {servicesToDisplay.length === 0 ? (
          <div className="no-products-message 
          text-center text-xl text-red-600">No such services available at this location.</div>
        ) : (
          servicesToDisplay.map((service, index) => (
            <ServiceCard
              service={filteredServices === null ? service : service}
              key={index} 
              businessId={service.businessId} 
            />
          ))
        )}
        <ToastContainer />
      </div>
    )}
  </div>
</>


      );
};

      export default Index;
