import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapPicker = ({ onAddressSelect }) => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [formattedAddress, setFormattedAddress] = useState('');
  const [residence, setResidence] = useState('');
  const [additionalAddress, setAdditionalAddress] = useState('');
  const [addressName, setAddressName] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setSelectedLocation({ lat: latitude, lng: longitude });
      }, (error) => console.error("Geolocation error:", error));
    }
  }, []);

  const extractAddressComponents = (components) => {
    const addressMap = {};
    components.forEach((component) => {
      component.types.forEach((type) => {
        addressMap[type] = component.long_name;
      });
    });
    return addressMap;
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });

    // Fetch address details using the Geocoding API
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBMLGFyGSVbRy8FVFp2MOzo9IVdxXFEBs8`;
    const response = await fetch(geocodeUrl);
    const data = await response.json();

    if (data.results[0]) {
      const result = data.results[0];
      const components = result.address_components;
      const addressMap = extractAddressComponents(components);

      // Set all address details
      setFormattedAddress(result.formatted_address);
      setResidence(`Crispy Broast, ${addressMap['sublocality'] || 'N/A'}, ${addressMap['administrative_area_level_1'] || 'N/A'}, ${addressMap['locality'] || 'N/A'}, ${addressMap['country'] || 'N/A'}`);
      setAdditionalAddress(` ${addressMap['sublocality'] || 'N/A'} Town, ${addressMap['locality'] || 'N/A'}, Karachi City, ${addressMap['administrative_area_level_1'] || 'N/A'}, ${addressMap['country'] || 'N/A'}`);
      
      const addressData = {
        street: addressMap['street_number'] || "12",
        area: addressMap['sublocality'] || "North Nazimabad",
        city: addressMap['locality'] || "Karachi",
        province: addressMap['administrative_area_level_1'] || "Sindh",
        country: addressMap['country'] || "Pakistan",
        postalCode: addressMap['postal_code'] || "74600",
        longitude: lng.toString(),
        latitude: lat.toString(),
        residence: residence,
        additionalAddress: additionalAddress,
        formattedAddress: result.formatted_address,
        addressName: addressName,
      };

      onAddressSelect(addressData);  // Pass address data to the parent component
    }
    closeMap();
  };

  const openMap = () => setIsMapOpen(true);
  const closeMap = () => setIsMapOpen(false);

  return (
    <div>
      <input
        type="text"
        onClick={openMap}
        readOnly
        placeholder="Click to select address"
        value={formattedAddress}
      />

      {isMapOpen && (
        <LoadScript googleMapsApiKey="AIzaSyBMLGFyGSVbRy8FVFp2MOzo9IVdxXFEBs8">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            center={selectedLocation || { lat: 37.7749, lng: -122.4194 }}
            zoom={10}
            onClick={handleMapClick}
          >
            {selectedLocation && <Marker position={selectedLocation} />}
          </GoogleMap>
          <button onClick={closeMap}>Close Map</button>
        </LoadScript>
      )}
    </div>
  );
};

export default MapPicker;
