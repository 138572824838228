import React from 'react';
import Grocery from '../assests/banner-grocery.png';
import bg from '../assests/shop-banner-loop.png';
import { FaShoppingCart } from 'react-icons/fa';

const ShopHero = ({
  searchQuery,
  setSearchQuery,
  handleSearch,
  heading,
  showSearch,
}) => {
  return (
    <>
      <div
        data-aos="fade-left"
        className="w-full  pt-20 flex items-center relative  font-poppins"
      >
        {/* MAIN HERO SECTION CODE  */}
        <div className="">
          <div className="lg:flex justify-between items-center mt-10 w-full">
            <div className="relative ">
              <img src={bg} alt="" />
              <div className=" text-center left-0 right-0 absolute xl:top-[40%] lg:top-[25%] md:text-[35px] md:top-[15%] sm:text-[24px] sm:top-[18%] text-[24px] top-[15%] ">
                <h1 className="xl:text-[70px] lg:text-[50px] text-uppercase text-white font-semibold">
                  {heading}
                </h1>
              </div>
              {showSearch && (
                <div className="container">
                  <div className="search  xl:py-[2rem] lg:pt-[1rem] lg:pb-[2rem] md:pt-[1rem] md:pb-[2rem] sm:pt-[1rem] sm:pb-[2rem] px-10 w-[80%] pt-[1rem] pb-[2rem] mx-auto shadow-lg absolute xl:bottom-[-20%] lg:bottom-[-35%] md:bottom-[-35%] sm:bottom-[-35%]   left-0 right-0 bg-white">
                    <h4 className="xl:text-lg lg:text-lg md:text-base sm:text-base text-[14px]  md:mb-2">
                      Search Best Deals
                    </h4>
                    <div className="xl:flex lg:flex-nowrap lg:flex md:flex-nowrap md:flex sm:flex-nowrap sm:flex flex-wrap justify-between items-center">
                      <input
                        type="text"
                        className="w-[100%] xl:h-14 lg:h-12 md:h-10 sm:h-10 border border-black border-solid"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div className="flex justify-center items-center">
                        <div className=" flex gap-4 z-50 items-center xl:ml-3 lg:ml-3 md:ml-3 sm:ml-0 ml-0 w-full">
                          <button
                            onClick={handleSearch}
                            className="thm-btn theme-caret font-bold relative bg-[#273B60] transition-all duration-300 hover:scale-105 xl:text-lg lg:text-lg md:text-base sm:text-base text-[14px] rounded-lg btn  xl:py-3 xl:px-12 lg:py-3 lg:px-10 md:py-2 md:px-12 sm:py-2 sm:px-8 sm:text-[14px] py-1 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mt-2 w-full  text-white"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopHero;
