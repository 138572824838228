import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useQuery } from '@apollo/client';
import { GET_ORDERS_BY_CUSTOMER_AND_STATUS, GET_BOOKINGS_BY_CUSTOMER_ID_AND_STATUS } from '../GraphQL/mutation'; // Adjust the import path

const HistoryModal = ({ isOpen, onClose }) => {
  const customerId = localStorage.getItem('userId'); // Retrieve user ID from local storage
  const [activeTab, setActiveTab] = useState('orders'); // 'orders' or 'bookings'
  const [status, setStatus] = useState('pending'); // 'pending', 'inProgress', or 'completed'

  // Fetch orders data
  const { data: orderData, loading: orderLoading, error: orderError } = useQuery(GET_ORDERS_BY_CUSTOMER_AND_STATUS, {
    variables: { customerId, status, pageNo: 0, noOfElements: 10 },
    skip: activeTab !== 'orders',
    onCompleted: (data) => {
      console.log('Orders Response:', data);
    },
    onError: (error) => {
      console.error('Error fetching orders:', error);
    },
  });

  // Fetch bookings data
  const { data: bookingData, loading: bookingLoading, error: bookingError } = useQuery(GET_BOOKINGS_BY_CUSTOMER_ID_AND_STATUS, {
    variables: { customerId, statusType: status, pageNo: 0, noOfElements: 10 },
    skip: activeTab !== 'bookings',
    onCompleted: (data) => {
      console.log('Bookings Response:', data);
    },
    onError: (error) => {
      console.error('Error fetching bookings:', error);
    },


  });

  // Handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setStatus('pending'); // Reset status when switching tabs
  };

  // Handle status change
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
    >
      <div
        className={`fixed right-0 top-0 w-80 bg-white h-full p-4 shadow-lg transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
        style={{ overflowY: 'auto' }} // Make the modal scrollable
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="text-xl font-semibold mb-4">User History</h2>

        {/* Tabs */}
        <div className="flex mb-4">
          <button
            onClick={() => handleTabChange('bookings')}
            className={`flex-1 py-2 text-center ${activeTab === 'bookings' ? 'bg-gray-200' : 'bg-gray-100'} rounded-t-md`}
          >
            Bookings
          </button>
          <button
            onClick={() => handleTabChange('orders')}
            className={`flex-1 py-2 text-center ${activeTab === 'orders' ? 'bg-gray-200' : 'bg-gray-100'} rounded-t-md`}
          >
            Orders
          </button>
        </div>

        {/* Status Filter */}
        <div className="p-4">
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Status:</label>
            <div className="flex gap-4">
              <label>
                <input
                  type="radio"
                  name="status"
                  value="pending"
                  checked={status === 'pending'}
                  onChange={handleStatusChange}
                  className="mr-2"
                />
                Pending
              </label>
              <label>
                <input
                  type="radio"
                  name="status"
                  value="inProgress"
                  checked={status === 'inProgress'}
                  onChange={handleStatusChange}
                  className="mr-2"
                />
                In Progress
              </label>
              <label>
                <input
                  type="radio"
                  name="status"
                  value="completed"
                  checked={status === 'completed'}
                  onChange={handleStatusChange}
                  className="mr-2"
                />
                Completed
              </label>
            </div>
          </div>

          {/* Content based on active tab */}
          {activeTab === 'bookings' ? (
         <div>
         {bookingLoading ? (
           <p>Loading bookings...</p>
         ) : bookingError ? (
           <p>Error loading bookings: {bookingError.message}</p>
         ) : bookingData?.getBookingsByCustomerIdAndStatus?.data?.length > 0 ? (
           <ul>
             {bookingData.getBookingsByCustomerIdAndStatus.data.map((booking) => (
               <li key={booking.bookingId} className="mb-4 p-4 border border-gray-300 rounded" style={{fontSize:'15px'}}>
                 <div>
                   <strong>Booking ID:</strong> {booking.bookingId}
                 </div>
                 <div>
                   <strong>Status:</strong> {booking.status}
                 </div>
                 <div>
                   <strong>Business:</strong> {booking.business.businessInfo.name}
                 </div>
                 <div>
                   <strong>Services:</strong>
                   {Array.isArray(booking.bookingDetails.serviceCartItems) && booking.bookingDetails.serviceCartItems.length > 0 ? (
                     <ul>
                       {booking.bookingDetails.serviceCartItems.map((item) => (
                         <li key={item.serviceId}>
                           {item.serviceName} - Price: {item.price} - Discount: {item.discount || 0} - Discounted Price: {item.discountedPrice || item.price}
                         </li>
                       ))}
                     </ul>
                   ) : (
                     <p>No services available.</p>
                   )}
                 </div>
                 <div>
                   <strong>Total Amount:</strong> {booking.bookingDetails.totalAmount}
                 </div>
                 <div>
                   <strong>Slot Number:</strong> {booking.bookingDetails.slotNumber}
                 </div>
                 {/* <div>
                   <strong>Time:</strong> {booking.bookingDetails.timeObj.startTime} to {booking.bookingDetails.timeObj.endTime}
                 </div> */}
               </li>
             ))}
           </ul>
         ) : (
           <p>No bookings available.</p>
         )}
       </div>
       
          ) : (
            <div>
              {orderLoading ? (
                <p>Loading orders...</p>
              ) : orderError ? (
                <p>Error loading orders: {orderError.message}</p>
              ) : orderData?.getOrdersByCustomerAndStatus?.data?.length > 0 ? (
                <div>
                  {orderData.getOrdersByCustomerAndStatus.data.map((order) => (
                    <div key={order.orderId} className="mb-4 p-4 border border-gray-300 rounded">
                      <p><strong>Order Type:</strong> {order.orderType || 'ONLINE'}</p>
                      <div>
                        <strong>Products:</strong>
                        <ul>
                          {order.orderDetails?.productCartItems?.length > 0 ? (
                            order.orderDetails.productCartItems.map((item, index) => (
                              <li key={index}>
                                {item.productName || 'N/A'} 
                                <br />Quantity: {item.quantity || 'N/A'} <br /> Price: {item.price || 'N/A'}
                              </li>
                            ))
                          ) : (
                            <p>No products available.</p>
                          )}
                        </ul>
                      </div>
                      <p><strong>Total Amount:</strong> {order.orderDetails?.totalAmount || 'N/A'}</p>
                      <p><strong>Status:</strong> {order.status || 'N/A'}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No orders available.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryModal;
