import React, { useEffect, useState } from 'react';
import './ServiceTimeSlots.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SLOTS_BY_BUSINESS_AND_SERVICE, CREATE_BOOKING_SERVICES } from '../GraphQL/mutation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const ServiceTimeSlots = ({ service, businessId, onClose }) => {
  const [userId, setUserId] = useState('aaaaaaaaaaaaaaaaaaaaaaaa');
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const imageBaseUrl = 'https://storage.googleapis.com/awkat-o-salat/';
  const imageUrl = service.listImagePath && service.listImagePath.length > 0
    ? `${imageBaseUrl}${service.listImagePath[0]}`
    : `${imageBaseUrl}default-image.jpg`;
console.log(service,businessId);
  const [getSlots, { loading: loadingSlots, error: errorSlots, data }] = useLazyQuery(GET_SLOTS_BY_BUSINESS_AND_SERVICE);

  const navigate = useNavigate(); // Initialize useNavigate hook

  const [createBookingServices, { loading: loadingBooking, error: errorBooking }] = useMutation(CREATE_BOOKING_SERVICES);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    }

    if (businessId && service.serviceName) {
      getSlots({
        variables: {
          businessId,
          date: currentDate,
          serviceName: service.serviceName,
          customerId: userId,
        },
      });
    }
  }, [businessId, service.serviceName, userId, getSlots]);

  const isTimeInPast = (startTime) => {
    const currentTime = new Date();
    const slotStartTime = new Date(startTime);
    return slotStartTime < currentTime;
  };

  const handleSlotSelect = (index) => {
    const slot = slots[index];
    if (isTimeInPast(slot.timeObj.startTime)) {
      toast.error('You cannot select past time slots.');
    } else {
      setSelectedSlot(index);
    }
  };

  const handleBookNow = () => {
    if (selectedSlot === null) {
      toast.error('Please select a time slot.');
      return;
    }

    const selectedSlotData = slots[selectedSlot];
    const bookingInput = {
      businessId,
      slotNumber: selectedSlotData.slotNumber,
      date: currentDate,
      timeObj: {
        startTime: selectedSlotData.timeObj.startTime,
        endTime: selectedSlotData.timeObj.endTime,
      },
      totalAmount: 100,
      instructions: 'Good service dena',
      serviceCartItems: {
        serviceId: service.serviceId || 'aaaaaa',
        serviceName: service.serviceName,
        price: service.price,
        discount: service.discount || 0,
        discountedPrice: service.discountedPrice || 0,
        listImagePath: imageUrl,
      },
    };
      console.log(selectedSlotData,userId);
      console.log('booking input ',bookingInput);
    // Loading toast
    const loadingToastId = toast.loading('Booking in progress... Please wait.');

    createBookingServices({
      variables: {
        userId,
        CreateBookingInput: bookingInput,
      },
      onCompleted: (response) => {
        console.log('Booking mutation completed:', response);
        if (response) {
          // Close loading toast and show success message
          toast.dismiss(loadingToastId);
          toast.success('Booking made successfully!');
alert("Booking done sucessfully");
          // Delay to allow Toast to be visible
        } else {
          // Update loading toast to error state if no response
          toast.update(loadingToastId, {
            render: 'Booking failed. No response received.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
        onClose(); // Close the modal after showing the alert
      },
      onError: (error) => {
        console.error('Booking error:', error);
        // Update the loading toast to error state
        toast.update(loadingToastId, {
          render: `Error: ${error.message || 'Unknown error occurred'}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      },
    });
  };

  if (loadingSlots) return <p>Loading slots...</p>;
  if (errorSlots) return <p>Error fetching slots: {errorSlots.message}</p>;

  // Filter to show only future slots
  const slots = data?.getSlotsByBusinessAndService?.data.filter(slot => !isTimeInPast(slot.timeObj.startTime)) || [];
    
  return (
    <>
      <ToastContainer position="top-right" autoClose={27000} />
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Select a Time Slot for {service.serviceName || 'Unnamed Service'}</h2>
          <p>Date: {currentDate}</p>
          <div className="slots-container">
            {slots.length > 0 ? (
              <div className="slots-list">
                {slots.map((slot, index) => (
                  <label key={slot.slotNumber} className="slot-item">
                    <input
                      type="radio"
                      name="timeSlot"
                      value={index}
                      checked={selectedSlot === index}
                      onChange={() => handleSlotSelect(index)}
                    />
                    <span className="slot-details">
                      Slot {slot.slotNumber} - {new Date(slot.timeObj.startTime).toLocaleTimeString()} to{' '}
                      {new Date(slot.timeObj.endTime).toLocaleTimeString()} - Available: {slot.totalAvailable}, Booked: {slot.totalBooked}
                    </span>
                  </label>
                ))}
              </div>
            ) : (
              <p>No future slots available for the selected date and service.</p>
            )}
          </div>

          <button className="close-btn" onClick={onClose}>Close</button>
          <button className="book-now-btn" onClick={handleBookNow}>Book Now</button>
        </div>
      </div>
    </>
  );
};

export default ServiceTimeSlots;
