import React, { useRef, useState } from 'react';
import './styles.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import { useNavigate } from 'react-router-dom'; // For navigation

const SupportForm = () => {
  const formRef = useRef(); // Using ref for the form
  const navigate = useNavigate(); // Initialize navigate hook
  const [form, setForm] = useState({
    problem: '',
    issueType: '',
    urgency: '',
    phone: '',
    email: '',
    additionalComments: '',
  });

  const [loading, setLoading] = useState(false);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      to_name: 'Tajjam Support Team',
      from_name: form.email,
      problem: form.problem,
      issuetype: form.issueType,
      urgency: form.urgency,
      phone: form.phone,
      email: form.email,
      additionalcomments: form.additionalComments,
    };

    emailjs
      .send(
        'service_g5vdpaf', // Service ID
        'template_33wuaxj', // Template ID
        templateParams,
        'tssfyhQFsarl_RGon' // Public key
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);

          // Show success toast
          toast.success('Query submitted successfully!');

          // Reset form state
          setForm({
            problem: '',
            issueType: '',
            urgency: '',
            phone: '',
            email: '',
            additionalComments: '',
          });

          setLoading(false);

          // Redirect after 2 seconds
          setTimeout(() => navigate('/'), 2000);
        },
        (error) => {
          console.log('FAILED...', error);

          // Show error toast
          toast.error('Failed to send message. Please try again.');
          setLoading(false);
        }
      );
  };

  return (
    <div className="support-form-container">
      <h1 className="title">SUPPORT</h1>
      <p className="subtitle">
        We would love to hear from you on how we can improve your experience!
      </p>
      <form ref={formRef} className="support-form" onSubmit={handleSubmit}>
        <label htmlFor="problem" className="form-label">
          What problem are you facing?
        </label>
        <textarea
          id="problem"
          name="problem"
          className="form-input"
          placeholder="Describe your issue here..."
          value={form.problem}
          onChange={handleChange}
          required
        />

        <label htmlFor="issueType" className="form-label">
          Type of Issue
        </label>
        <select
          id="issueType"
          name="issueType"
          className="form-input"
          value={form.issueType}
          onChange={handleChange}
        >
          <option value="">Select issue type</option>
          <option value="technical">Technical Issue</option>
          <option value="billing">Billing Issue</option>
          <option value="account">Account Issue</option>
          <option value="feedback">General Feedback</option>
          <option value="other">Other</option>
        </select>

        <label htmlFor="urgency" className="form-label">
          How urgent is your issue?
        </label>
        <select
          id="urgency"
          name="urgency"
          className="form-input"
          value={form.urgency}
          onChange={handleChange}
        >
          <option value="">Select urgency level</option>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
          <option value="critical">Critical</option>
        </select>

        <label htmlFor="phone" className="form-label">
          Phone number to contact you
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          className="form-input"
          placeholder="Phone number"
          value={form.phone}
          onChange={handleChange}
        />

        <label htmlFor="email" className="form-label">
          Email to contact you
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="form-input"
          placeholder="Email"
          value={form.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="additionalComments" className="form-label">
          Additional Comments
        </label>
        <textarea
          id="additionalComments"
          name="additionalComments"
          className="form-input"
          placeholder="Any other details you’d like us to know"
          value={form.additionalComments}
          onChange={handleChange}
        />

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Submitting...' : 'SUBMIT'}
        </button>
      </form>

      {/* Toast container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default SupportForm;
