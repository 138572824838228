import React, { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css'; // Import CSS for react-tabs
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { useApolloClient, gql } from '@apollo/client';
import { Chart } from 'react-google-charts';
import { GET_TASKS_BY_ID } from '../../GraphQL/mutation';
import Loader from '../../common/Loader/Loader';
import client from '../../GraphQL/ApolloClient';

const parseData = (rawData) => {
  const parsedData = JSON.parse(rawData);
  const formattedData = [['From', 'To', 'Weight']];

  const extractLinks = (node) => {
    node?.children?.forEach((child) => {
      formattedData.push([node.name, child.name, child.weight]);
      extractLinks(child);
    });
  };

  extractLinks(parsedData);

  return formattedData;
};

const Features = () => {
  const [taskData, setTaskData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTaskById = async () => {
      try {
        const response = await client.query({
          query: GET_TASKS_BY_ID,
          variables: { taskId: '663f630fe250cd387411d9e3' }, // Replace with your taskId
        });

        if (response.data) {
          setTaskData(response?.data?.getTasksById?.data);
        }

        if (response.errors) {
          setError(response.errors[0].message || 'An error occurred');
        }
      } catch (error) {
        console.error('Caught error:', error);
        if (error.networkError) {
          console.error('Network error:', error.networkError);
        }
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
        setError(error.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchTaskById();
  }, [client]);

  return (
    <>
      <Navbar />
      {loading && <Loader />}
      <div className="container mx-auto my-8 px-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">
          Features Breakdown
        </h1>
        <div className="bg-white rounded-lg shadow-md p-6">
          {error ? (
            <div className="text-red-500 text-xl">{error}</div>
          ) : (
            <Chart
              chartType="Sankey"
              width="100%"
              height="400px"
              data={parseData(taskData)}
              options={{
                sankey: {
                  node: {
                    label: {
                      fontSize: 14,
                      fontWeight: 600,
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Features;
