import React, { useState } from 'react';
import './ServiceCard.css';
import ServiceTimeSlots from './ServiceTimeSlots'; // Ensure this is correctly imported
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useSelector } from 'react-redux'; // Import useSelector

const ServiceCard = ({ service, businessId }) => {
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate(); // Initialize useNavigate
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated); // Get the authentication status
  const imageBaseUrl = 'https://storage.googleapis.com/awkat-o-salat/';

  if (!service) {
    return <div className="service-card">No service information available.</div>; // Handle case where service is null
  }

  const handleBookServiceClick = () => {
    if (isAuthenticated) {
      setShowModal(true); // Open modal when button is clicked
    } else {
      // Navigate to the signup page with the intended path
      navigate('/signup', { state: { from: '/services' } });
    }
  };

  const closeModal = () => {
    setShowModal(false); // Close modal
  };

  return (
    <div className="service-card">
      {service.listImagePath && service.listImagePath.length > 0 ? (
        <img
          src={`${imageBaseUrl}${service.listImagePath[0]}`}
          alt={service.serviceName || 'Service Image'} // Fallback alt text
          className="service-image"
          style={{ height: '200px' }}
        />
      ) : (
        <div className="service-image-placeholder" style={{ height: '200px', backgroundColor: '#f0f0f0' }}>
          No Image Available
        </div>
      )}

      <h3><b>{service.serviceName || 'Service Name Unavailable'}</b></h3>
      <p>{service.description || 'No description available.'}</p>
      <p>Status: {service.status || 'Status not provided.'}</p>
      <p>Price: ${service.price || 'N/A'}</p>
      {service.discount && <p>Discount: {service.discount}%</p>}
      {service.discountedPrice && <p>Discounted Price: ${service.discountedPrice}</p>}
      <p>Available Slots: {service.availableSlots || 'N/A'}</p>

      {/* Button to book service */}
      <button onClick={handleBookServiceClick} className="book-service-btn">
        Book a Service
      </button>

      {/* Conditionally render the modal */}
      {showModal && (
        <ServiceTimeSlots
          service={service}
          businessId={businessId} // Pass businessId to ServiceTimeSlots
          onClose={closeModal} // Pass function to close modal
        />
      )}
    </div>
  );
};

export default ServiceCard;
