/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import catImg from '../assests/kisspng-computer-keyboard-razer-blackwidow-ultimate-2-16--5b8b26ff84f196.8979452315358461435446.png';
import banImg from '../assests/DesktopQuadCat_186x116_health-beauty_B07662GN57_01.23._SY116_CB619238939_.png';
import banImg2 from '../assests/DesktopQuadCat_186x116_kitchen_B0126LMDFK_01.23._SY116_CB619238939_.png';
import banImg3 from '../assests/DesktopQuadCat_186x116_LP-HP_B08MYX5Q2W_01.23._SY116_CB619238939_.png';
import banImg4 from '../assests/Repeat Grid 1.png';
import Img4 from '../assests/img3.png';
import Img5 from '../assests/img4.png';
import Img6 from '../assests/img6.png';
import './Categories.css';
import Products from './BestDeals';

const Categories = () => {
  return (
    <div
      data-aos="fade-right"
      id="categories"
      className="font-poppins w-full pb-[6rem] bg-[#F8F8F8] px-[20px]"
    >
      <div className="container m-auto relative pt-[5rem] lg:pt-[6rem]">
        <div className="  xl:flex xl:flex-wrap md:flex md:flex-wrap lg:flex-wrap xl:justify-center">
          <div className="w-[80%] mt-5 mx-auto xl:w-[31%]  lg:w-[45%] lg:mx-auto lg:mb-3 sm:mt-5 sm:w-[80%] sm:mx-auto  md:w-[44%] md:mx-auto md:mb-3 xl:justify-center xl:mb-4 xl:m-3 xl:px-4 lg:px-4 lg:mt-4 md:px-4 bg-white shadow-class-custom rounded-xl p-5 ">
            <div className="flex justify-between">
              <h3 className="xl:text-2xl sm:text-[18px] md:text-lg  text-[15px] font-bold text-black">
                Gaming Accesories
              </h3>
              <span className="text-[14px] flex items-center text-[#E0B503]">
                see more
              </span>
            </div>
            <ul className="cats flex flex-wrap ">
              <li className="w-1/2">
                {' '}
                <img src={catImg} className="w-full" alt="" />
              </li>
              <li className="w-1/2">
                {' '}
                <img src={catImg} className="w-full" alt="" />
              </li>
              <li className="w-1/2">
                {' '}
                <img src={catImg} className="w-full" alt="" />
              </li>
              <li className="w-1/2">
                {' '}
                <img src={catImg} className="w-full" alt="" />
              </li>
            </ul>
          </div>
          <div className="w-[80%] mt-5 mx-auto xl:w-[31%]  lg:w-[45%] lg:mx-auto lg:mb-3 sm:mt-5 sm:w-[80%] sm:mx-auto  md:w-[44%] md:mx-auto md:mb-3 xl:justify-center xl:mb-4 xl:m-3 xl:px-4 lg:px-4 lg:mt-4 md:px-4 bg-white shadow-class-custom rounded-xl p-5 ">
            <h3 className="xl:text-2xl sm:text-[18px] md:text-lg text-[15px] mb-3 text-left font-bold text-black">
              Refresh Your Space
            </h3>
            <ul className="cats flex flex-wrap ">
              <li className="w-1/2  ">
                {' '}
                <img src={banImg} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Health And Beauty</p>{' '}
              </li>
              <li className="w-1/2 ">
                {' '}
                <img src={banImg2} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Kitchen</p>{' '}
              </li>
              <li className="w-1/2 ">
                {' '}
                <img src={banImg3} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Dinning</p>{' '}
              </li>
              <li className="w-1/2 ">
                {' '}
                <img src={banImg4} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Home</p>{' '}
              </li>
            </ul>
            <span className=" xl:text-[18px] md:text-[14px] lg:text-[14px] text-[14px]  cursor-pointer flex items-center text-[#E0B503]">
              see more
            </span>
          </div>
          <div className="w-[80%] mt-5 mx-auto xl:w-[31%]  lg:w-[45%] lg:mx-auto lg:mb-3 sm:mt-5 sm:w-[80%] sm:mx-auto  md:w-[44%] md:mx-auto md:mb-3 xl:justify-center xl:mb-4 xl:m-3 xl:px-4 lg:px-4 lg:mt-4 md:px-4 bg-white shadow-class-custom rounded-xl p-5 ">
            <h3 className="xl:text-2xl sm:text-[18px] md:text-lg text-[15px] mb-3 text-left font-bold text-black">
              Handpicked Music & Audio
            </h3>
            <ul className="cats flex flex-wrap ">
              <li className="w-1/2  ">
                {' '}
                <img src={banImg} className=" w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Health And Beauty</p>{' '}
              </li>
              <li className="w-1/2 ">
                {' '}
                <img src={banImg} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Kitchen</p>{' '}
              </li>
              <li className="w-1/2 ">
                {' '}
                <img src={banImg} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Dinning</p>{' '}
              </li>
              <li className="w-1/2 ">
                {' '}
                <img src={banImg} className="w-11/12" alt="" />{' '}
                <p className="text-sm text-left">Home</p>{' '}
              </li>
            </ul>
            <span className=" xl:text-[18px] md:text-[14px] lg:text-[14px] text-[14px]  cursor-pointer flex items-center text-[#E0B503]">
              see more
            </span>
          </div>
          <div className="w-[80%] mt-5 mx-auto xl:w-[31%]  lg:w-[45%] lg:mx-auto lg:mb-3 sm:mt-5 sm:w-[80%] sm:mx-auto  md:w-[44%] md:mx-auto md:mb-3 xl:justify-center xl:mb-4 xl:m-3 xl:px-4 lg:px-4 lg:mt-4 md:px-4 bg-white shadow-class-custom rounded-xl p-5 ">
            <h3 className="xl:text-2xl sm:text-[18px] md:text-lg  text-[15px] mb-3 font-bold text-black">
              Fill Your Easter Basket
            </h3>
            <ul className="cats flex flex-wrap ">
              <li className="w-full  ">
                {' '}
                <img src={Img4} className="w-full  " alt="" />{' '}
              </li>
            </ul>
            <span className=" xl:text-[18px] md:text-[14px] lg:text-[14px] text-[14px] mt-2  cursor-pointer flex items-center text-[#E0B503]">
              Shop Gifts
            </span>
          </div>
          <div className="w-[80%] mt-5 mx-auto xl:w-[31%]  lg:w-[45%] lg:mx-auto lg:mb-3 sm:mt-5 sm:w-[80%] sm:mx-auto  md:w-[44%] md:mx-auto md:mb-3 xl:justify-center xl:mb-4 xl:m-3 xl:px-4 lg:px-4 lg:mt-4 md:px-4 bg-white shadow-class-custom rounded-xl p-5 ">
            <h3 className="xl:text-2xl sm:text-[18px] md:text-lg  text-[15px] mb-3 font-bold text-black">
              Celebrate The Women In Your Life
            </h3>
            <ul className="cats flex flex-wrap ">
              <li className="w-full   ">
                {' '}
                <img src={Img5} className="w-full  " alt="" />{' '}
              </li>
            </ul>
            <span className=" xl:text-[18px] md:text-[14px] lg:text-[14px] text-[14px]  mt-2  cursor-pointer flex items-center text-[#E0B503]">
              Shop Gifts
            </span>
          </div>
          <div className="w-[80%] mt-5 mx-auto xl:w-[31%]  lg:w-[45%] lg:mx-auto lg:mb-3 sm:mt-5 sm:w-[80%] sm:mx-auto  md:w-[44%] md:mx-auto md:mb-3 xl:justify-center xl:mb-4 xl:m-3 xl:px-4 lg:px-4 lg:mt-4 md:px-4 bg-white shadow-class-custom rounded-xl p-5 ">
            <h3 className="xl:text-2xl sm:text-[18px] md:text-lg mb-3 text-[15px] font-bold text-black">
              Deals In Vegetable
            </h3>
            <ul className="cats flex flex-wrap ">
              <li className="w-full   ">
                {' '}
                <img src={Img6} className=" w-full  " alt="" />{' '}
              </li>
            </ul>
            <span className=" xl:text-[18px] md:text-[14px] lg:text-[14px] text-[14px]  mt-2  cursor-pointer flex items-center text-[#E0B503]">
              Shop Gifts
            </span>
          </div>
        </div>

        <div className="flex justify-center mt-10 items-center"></div>
      </div>
      <Products />
    </div>
  );
};

export default Categories;
