import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Checkout.css';
import Navbar from '../../components/Navbar';
import { useMutation } from '@apollo/client';
import { CREATE_ORDER } from '../../GraphQL/mutation'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Checkout = () => {
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);

  const [address, setAddress] = useState({ street: '', city: '' });
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [createOrder] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      toast.success('Order successfully placed!');
      navigate('/orderconfirmation');
    },
    onError: (error) => {
      console.error('GraphQL Error:', error);
      toast.error(`Failed to place the order. Error: ${error.message}`);
    },
  });

  // Load user info from local storage when the component mounts
  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      const email = userInfo.email || userInfo.username;
      const extractedName = email.split('@')[0]; // Extract the name part from email
      setName(extractedName);
      setEmail(email);
    }
  }, []);

  const handlePlaceOrder = (e) => {
    e.preventDefault();
    
    // Show processing toast
    toast.info('Order is being processed...', {
      autoClose: 2000,
    });
    
    const orderData = {
      businessId: '66ae6acb2110074570d288af', // Use correct businessId
      address: address,
      orderType: 'ONLINE',
      categories: cartItems.map((item) => item.category),
      products: cartItems.map((item) => ({
        productName: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
      customerName: name,
      email: email,
      phoneNumber: phone,
      totalAmount: totalAmount,
      instructions: "give fresh milk", // Add other missing fields if needed
    };

    const timeObj = {
      startTime: "2024-09-14T01:00:00.000+00:00",
      endTime: "2024-09-15T01:00:00.000+00:00"
    };

    createOrder({
      variables: {
        createOrderInput: orderData,
        timeObj: timeObj,
      },
    });
  };

  return (
    <>
      <Navbar />
      <div className="checkout-container">
        <div className="order-details">
          <h2>🛒 Your Cart</h2>
          {cartItems.map((item) => (
            <div key={item.id} className="order-item">
              <img
                src={`https://storage.googleapis.com/awkat-o-salat/${item.imageName}`}
                alt={item.name}
                className="product-image"
              />
              <p>
                {item.quantity} Piece(s) - <b>{item.name}</b> - Price: ${item.totalPrice}
              </p>
            </div>
          ))}
          <hr />
          <p>Subtotal: ${totalAmount.toFixed(2)}</p>
          <p className="total">Total: ${totalAmount.toFixed(2)}</p>
        </div>

        <div className="checkout-form">
          <h2>Checkout</h2>
          <form onSubmit={handlePlaceOrder}>
            <label>
              Name:
              <input
                type="text"
                value={name}
                readOnly // Making this field read-only
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={email}
                readOnly // Making this field read-only
                required
              />
            </label>
            <label>
              Street:
              <input
                type="text"
                placeholder="Enter street address"
                value={address.street}
                onChange={(e) => setAddress({ ...address, street: e.target.value })}
                required
              />
            </label>
            <label>
              City:
              <input
                type="text"
                placeholder="Enter city"
                value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                required
              />
            </label>
            <label>
              Phone No:
              <input
                type="text"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </label>
            <button type="submit" className="proceed-button">
              Place Order
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Checkout;
