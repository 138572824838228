import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ADD_SERVICE } from '../../GraphQL/mutation';
import './AddService.css';

const AddService = () => {
  const { businessId } = useParams(); // Get businessId from URL
  const [isServiceInfoOpen, setIsServiceInfoOpen] = useState(true);
  const [isServiceDetailsOpen, setIsServiceDetailsOpen] = useState(false);
  const [isServiceImagesOpen, setIsServiceImagesOpen] = useState(false);

  const [serviceName, setServiceName] = useState('');
  const [description, setDescription] = useState('');
  const [numWorkers, setNumWorkers] = useState('');
  const [serviceCharges, setServiceCharges] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [images, setImages] = useState([]);

  const [addService] = useMutation(ADD_SERVICE);

  const toggleServiceInfo = () => setIsServiceInfoOpen(!isServiceInfoOpen);
  const toggleServiceDetails = () => setIsServiceDetailsOpen(!isServiceDetailsOpen);
  const toggleServiceImages = () => setIsServiceImagesOpen(!isServiceImagesOpen);

  // Calculate discounted price whenever discount or serviceCharges changes
  const handleDiscountChange = (e) => {
    const discountValue = e.target.value;
    setDiscount(discountValue);
    if (serviceCharges) {
      const calculatedDiscountedPrice = (serviceCharges - serviceCharges * (discountValue / 100)).toFixed(2);
      setDiscountedPrice(calculatedDiscountedPrice);
    }
  };

  const handleAddService = () => {
    if (!serviceName || !description || !numWorkers || !serviceCharges) {
      alert('Please fill all required fields');
      return;
    }

    const imagePaths = images.map((image) => image.name || image);

    addService({
      variables: {
        input: {
          businessId: businessId, // Pass businessId from URL
          service: {
            serviceName,
            description,
            status: "AVAILABLE",
            price: parseFloat(serviceCharges),
            discount: parseFloat(discount),
            discountedPrice: parseFloat(discountedPrice),
            likedCount: 0,
            purchasedCount: 0,
            availableSlots: parseInt(numWorkers),
            imagePaths,
            listImagePath: imagePaths,
          },
        },
      },
    })
      .then((response) => {
        alert('Service added successfully!');
        console.log('Service Data:', response.data);
      })
      .catch((error) => {
        console.error('Error adding service:', error);
      });
  };

  return (
    <div className="add-service-container">
      <div className="add-service-content">
        <header className="add-service-header">
          <h1>Add Services</h1>
        </header>

        {/* Service Info Section */}
        <section className={`collapsible-section ${isServiceInfoOpen ? 'open' : ''}`}>
          <h2 onClick={toggleServiceInfo}>Service Info</h2>
          {isServiceInfoOpen && (
            <div className="input-group">
              <input
                type="text"
                placeholder="Service Name *"
                className="input-field"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Description *"
                className="input-field"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          )}
        </section>

        {/* Service Details Section */}
        <section className={`collapsible-section ${isServiceDetailsOpen ? 'open' : ''}`}>
          <h2 onClick={toggleServiceDetails}>Service Details</h2>
          {isServiceDetailsOpen && (
            <div className="input-group">
              <input
                type="number"
                placeholder="Number of Workers *"
                className="input-field"
                value={numWorkers}
                onChange={(e) => setNumWorkers(e.target.value)}
              />
              <input
                type="number"
                placeholder="Service Charges *"
                className="input-field"
                value={serviceCharges}
                onChange={(e) => setServiceCharges(e.target.value)}
              />
              <input
                type="number"
                placeholder="Discount %"
                className="input-field"
                value={discount}
                onChange={handleDiscountChange}
              />
              <input
                type="number"
                placeholder="Discounted Price"
                className="input-field"
                value={discountedPrice}
                readOnly
              />
            </div>
          )}
        </section>

        {/* Service Images Section */}
        <section className={`collapsible-section ${isServiceImagesOpen ? 'open' : ''}`}>
          <h2 onClick={toggleServiceImages}>Service Images</h2>
          {isServiceImagesOpen && (
            <div className="input-group">
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={(e) => setImages([...e.target.files])}
              />
              <div className="image-preview">
                {images && Array.from(images).map((image, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt="Preview"
                    className="preview-image"
                  />
                ))}
              </div>
            </div>
          )}
        </section>

        <button className="add-service-button" onClick={handleAddService}>
          Add Service
        </button>
      </div>
    </div>
  );
};

export default AddService;
