// DepartmentDropdown.js
import React, { useState } from 'react';
import bananaIcon from '../../assests/banana.png';
import sofaIcon from '../../assests/sofa.png';
import tvIcon from '../../assests/tv.png';
import ArrowDropDownIcon from '../../assests/dropdownIcon.png';
import './Header.css';

const DepartmentDropdown = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div 
            className="departments" 
            onMouseEnter={() => setDropdownVisible(true)} 
            onMouseLeave={() => setDropdownVisible(false)}
        >
            <button className="departments-button">
                Departments
                <img src={ArrowDropDownIcon} alt="Dropdown Icon" className="dropdown-icon" />
            </button>

            {dropdownVisible && (
                <div className="dropdown-menu">
                    <div className="dropdown-section">
                        <img src={bananaIcon} alt="Grocery Icon" className="section-icon" />
                        <div>
                            <h4>Grocery</h4>
                            <ul>
                                <li>Fresh Food</li>
                                <li>Pantry</li>
                                <li>Snacks</li>
                                <li>Frozen Food</li>
                                <li>Candy</li>
                                <li>Beverages</li>
                                <li>Coffee</li>
                                <li>Shop All Grocery</li>
                            </ul>
                        </div>
                    </div>
                    <div className="dropdown-section">
                        <img src={sofaIcon} alt="Home Icon" className="section-icon" />
                        <div>
                            <h4>Home</h4>
                            <ul>
                                <li>Storage & Organization</li>
                                <li>Furniture</li>
                                <li>Mattresses</li>
                                <li>Bedding</li>
                                <li>Appliances</li>
                                <li>Home Improvement</li>
                                <li>Kitchen & Dining</li>
                                <li>Shop all Home</li>
                            </ul>
                        </div>
                    </div>
                    <div className="dropdown-section">
                        <img src={tvIcon} alt="Electronics Icon" className="section-icon" />
                        <div>
                            <h4>Electronics</h4>
                            <ul>
                                <li>TVs & Home Theater</li>
                                <li>Computers</li>
                                <li>Cell Phones</li>
                                <li>iPads & Tablets</li>
                                <li>Audio</li>
                                <li>Wearable Technology</li>
                                <li>Gaming</li>
                                <li>Shop all Electronics</li>
                            </ul>
                        </div>
                    </div>
                    <div className="dropdown-section">
        <div>
          <h4>More</h4>
          <h5>More Departments</h5>
          <ul>
            <li>New & Trending</li>
            <li>Auto & Tires</li>
            <li>Clothing & Shoes</li>
            <li>Office</li>
            <li>Toys, Games & Books</li>
            <li>Restaurant Supplies</li>
            <li>Shop all departments</li>
          </ul>
        </div>
      </div>

      <div className="dropdown-section">
        <div>
          <h4>Services</h4>
          <h5><a href="/services">Tajjam’s Services</a></h5>
          <ul>
            <li>Health Services</li>
            <li>Auto Care & Buying</li>
            <li>Protection & Installation</li>
            <li>Home Improvement</li>
            <li>Travel & Entertainment</li>
            <li>Photos & Customization</li>
            <li>Business Services</li>
            <li>Club Services</li>
          </ul>
        </div>
      </div>
                    {/* Add other sections as needed */}
                </div>
            )}
        </div>
    );
};

export default DepartmentDropdown;
