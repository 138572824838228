/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import bg1 from "../assests/Group-1.png";
import bg2 from "../assests/ban-2.png";
import bg3 from "../assests/ban-3.png";
import bgE from "../assests/eclipse.png";


const Collections = () => {
  return (
    <>
      <div
        data-aos="fade-right"
        id="Collections"
        className="font-poppins w-full pb-[6rem] px-[20px]"
      >
        <div className="container m-auto relative max-xl:pt-[18rem]  xl:pt-[20rem]  lg:pt-[12rem] md:pt-[10rem] sm:pt-[5rem]">
        <img src={bgE} className="absolute xl:block lg:block md:block sm:block hidden top-[100%] xl:top-[12%] lg:top-[15%] lg:left-[-3%]  xl:left-[-3%] md:top-[5%]" />
          <div className="flex justify-center xl:flex-nowrap lg:flex-nowrap md:flex-nowrap sm:flex-nowrap  flex-wrap items-center">
            <div className="xl:w-4/12 w-[100%] mx-4 mb-4 relative text-center">
              <img src={bg1} alt="" className="w-[100%]" />
              <div className="text left-0 right-0 absolute  top-[2rem] xl:top-[5rem] md:right-0 md:left-0 sm:top-[2em]">
                <h3 className="xl:text-2xl sm:text-[12px] md:text-[18px] text-[20px] italic font-light text-[#273B60]">
                  Explore your food
                </h3>
                <h3 className="xl:text-[70px] sm:text-[24px] md:text-[36px] lg:text-[40px] text-[36px] lg:text-center  italic font-bold line-height-8 text-[#273B60]">
                  SHOP
                </h3>
              </div>
            </div>
            <div className="xl:w-4/12 w-[100%] mx-4 mb-4 relative text-left">
              <img src={bg2} alt="" className="w-[100%]" />
              <div className="xl:text-left md:pl-4 pl-8 sm:pl-2 md:text-left lg:text-left lg:pl-5 xl:pl-4 absolute left-0 right-0  xl:top-[5em] lg:top-[2em]  top-[2rem] text ">
                <h3 className="xl:text-2xl sm:text-[12px] italic lg:text-[16px] md:text-[18px] text-[20px] font-light text-[#273B60]">
                  20% OFF
                </h3>
                <h3 className="xl:text-[40px] sm:text-[16px] sm:leading-tight text-[36px] lg:text-[32px] md:text-[24px] xl:text-left md:left-0 md:right-0  lg:m-0  italic font-semibold  xl:mb-5  xl:mt-4 xl:leading-10 lg:leading-tight md:leading-tight leading-10 mb-3 text-[#E2652E]">
                An Organic <br />
                  Items
                </h3>
                {/* <h4 className="xl:text-[18px] text-[14px] w-[8rem] sm:text-[12px] sm:w-[5.5rem] md:text-[12px] lg:text-[12px] lg:w-[9rem] md:w-[6rem] xl:w-[12rem] font-normal line-height-8 text-[#707070]">
                Lorem ipsum dolor sit amet, consectetur 
                </h4> */}
              </div>
            </div>
            <div className="xl:w-4/12 w-[100%] mx-4 mb-4 md:right-0 md:left-0 relative text-left">
              <img src={bg3} alt="" className="w-[100%]" />
              <div className="text text-center left-0 right-0 sm:text-center absolute xl:top-[3rem] md:right[25%]  top-[2rem] sm:top-[2em]">
                <h3 className="xl:text-2xl text-[20px] sm:text-[12px] md:text-[18px] xl:text-[18px] xl:text-center italic font-light  text-white">
                100% NATURALS
                </h3>
                <h3 className="xl:text-[50px] text-[36px] sm:text-[24px] md:text-[30px]  text-center lg:text-3xl italic lg:text-center font-bold line-height-8 text-white">
                  Fresh Juices
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
