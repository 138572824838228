import React from 'react';
import Header from '../components/Header/index';
import Footer from '../components/Footer/index';

const test = () => {
  return (
   <>
   <Header/>
   <div>test

<Footer/>
        
</div>
   </>
  )
}

export default test