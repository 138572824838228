import React, { useState, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PROFILE } from '../../GraphQL/mutation';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UpdateProfile.css';
import { DELETE_USER } from '../../GraphQL/mutation';
import { useAuth } from '../../auth/authContext'; // Import the useAuth hook

const UpdateProfile = () => {
  const navigate = useNavigate();
  const { logout } = useAuth(); 
  // Retrieve data from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  const storedFirstName = localStorage.getItem('firstName') || '';
  const storedLastName = localStorage.getItem('lastName') || '';
  const storedEmail = userInfo.username || '';
  

  const userId = localStorage.getItem('userId'); // Retrieve userId if available

  // Initialize state with retrieved values
  const [firstName, setFirstName] = useState(storedFirstName);
  const [lastName, setLastName] = useState(storedLastName);
  const [email] = useState(storedEmail); // Email is read-only, so no need for setter
  const [isEditable, setIsEditable] = useState(false); // Control field editability
  const [deleteUser, { data, error, loading }] = useMutation(DELETE_USER);
  // Define mutation for updating user profile
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: (data) => {
      if (data?.updateUserProfile?.status === 200) {
        const updatedUserData = { firstName, lastName, username: email };
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);

        toast.success('Profile updated successfully!', {
          onClose: () => navigate('/'), // Redirect to home after success
        });
      }
    },
    onError: (error) => {
      console.error('Error updating profile:', error);
      toast.error(`Error updating profile: ${error.message}`);
    },
  });

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = {
      name: { firstName, lastName },
      phoneNumber: '', // Optional: adjust logic if needed
      userId,
      uploadImage: null,
      deleteImage: null,
    };

    try {
      await updateUserProfile({ variables: { input } });
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  // Enable edit mode when "Edit" button is clicked
  const handleEditClick = () => {
    setIsEditable(true);
  };
   // Delete user profile and log out the user
   const deleteProfile = async () => {
    const userId = localStorage.getItem('userId');
    
    try {
      const response = await deleteUser({
        variables: { userId },
      });

      if (response.data?.deleteUser?.status === 200) {
        // Show success toast notification
        toast.success('Profile deleted successfully!', {
          onClose: () => {
            // Clear localStorage
            localStorage.clear();
            // Log the user out and set isAuthenticated to false
            logout();
          },
        });
        console.log('Profile deleted successfully!');
      }
    } catch (err) {
      console.error('Error deleting profile:', err);
      // Show error toast notification
      toast.error('Failed to delete profile.');
    }
  };

  
  
  return (
    <>
      <div className="profile-container">
        <h2 className="profile-header">Update your Profile</h2>

        <div className="profile-picture">
        <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center">
                  <FaUser size={20} color="#333" />
                </div>
          <button
            type="button"
            className="edit-button"
            onClick={handleEditClick}
          >
            Edit
          </button>
        </div>

        <form className="profile-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name *</label>
            <input
              type="text"
              id="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={!isEditable}
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastName">Last Name *</label>
            <input
              type="text"
              id="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={!isEditable}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              readOnly
              className="text-gray-400" // Light text color to indicate read-only
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 rounded-lg bg-[#F16427] text-white font-medium hover:bg-[#C6471A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F16427]"
          >
            Save
          </button>
          
          <button
          onClick={deleteProfile}  disabled={loading}
            className="w-full py-2 mt-6 rounded-lg bg-[#F16427] text-white font-medium hover:bg-[#C6471A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F16427]"
          >
           {loading ? 'Deleting...' : 'Delete Profile'}
          </button>

        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default UpdateProfile;
