import { useEffect } from 'react';

const Toaster = ({ status, type, message, changeError }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      changeError((prevState) => ({ ...prevState, status: false }));
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const getClassName = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500 text-white';
      case 'warning':
        return 'bg-yellow-500 text-black';
      case 'error':
        return 'bg-red-500 text-white';
      default:
        return 'bg-gray-500 text-white';
    }
  };

  return (
    <div
      className={`fixed bottom-0 right-0 m-4 p-4 rounded-lg ${getClassName()}`}
    >
      {message}
    </div>
  );
};

export default Toaster;
