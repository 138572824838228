import React from "react";
import Grocery from "../assests/banner-grocery.png";
import CircleGif from "../assests/circle.gif";
import { FaShoppingCart } from "react-icons/fa";

const HeroShop = () => {
  return (
    <div
      data-aos="fade-left"
      className="w-full h-4/5 pt-20 flex items-center relative md:top-[3rem] top-[5rem] font-poppins"
    >
      {/* MAIN HERO SECTION CODE  */}
      <div className="container">
        <div className="xl:flex xl:nowrap nowrap sm:flex md:flex lg:flex xl:justify-between xl:items-center xl:w-full">
          {/* LEFT SECTION  */}
          <div className="xl:flex-1 sm:flex-wrap sm:w-2/5 pl-5 lg:w-2/5  md:w-3/5  xl:w-2/5 w-full xl:pl-5 items-center lg:mb-0 mb-[2rem]">
            {/* TEXT  */}
            <div className="">
              <div className="flex container  items-center mb-2">
                <h2 className="xl:text-[80px] text-[35px]  text-[#fdce0c] sm:mb-4 sm:text-[32px]  md:text-[40px] md:mb-4 lg:text-[55px]   lg:mb-4    hero-heading mt-6 font-bold mb-[0]">
                  Order Tasty Food
                </h2>
              </div>
              <h2 className="xl:text-[80px] text-[35px]  text-[#F16427] sm:mb-4 sm:text-[32px] md:text-[40px] md:mb-4 lg:text-[55px]  hero-heading font-bold mb-2 mt-[0]">
                & Great Delivery
              </h2>
            </div>

            {/* BUTTONS  */}
            <div className=" flex gap-4 z-50 items-center mt-5">
              <button className="thm-btn xl:text-[22px] text-[12px] py-2 px-5 xl:py-5 xl:px-10  lg:text-[18px] md:text-[16px] md:py-2  sm:text-[12px] sm:py-2 theme-caret font-bold relative bg-[#273B60] transition-all duration-300 hover:scale-110 rounded-lg btn  lg:py-3 lg:px-10 z-50 text-white">
                Shop Now
              </button>
            </div>
          </div>

          {/* RIGHT SECTION  */}

          <div className="xl:flex-1 sm:flex-wrap sm:w-3/5 lg:w-3/5  md:w-3/5  xl:w-3/5 w-full relative items-center flex justify-center lg:justify-end">
            <div className="img-main relative">
              <img src={Grocery} alt="" className=" w-100 z-50" />
              {/* <div className=" flex justify-end relative right-[-6rem] gap-4 mt-3">
              <div className="container">
              <button className="hover:scale-125 lg:justify-center  transition-all duration-300 font-bold text-sm relative rounded-full bg-[#F16427] btn py-3 px-10 text-white flex xl:justify-end items-center">
              <FaShoppingCart  className="mr-4 "/> Add To Cart
              </button>
              </div>
            </div> */}
              <div className="absolute top-[-3rem] lg:top-[-5rem] lg:right-0 hidden lg:block -z-50">
                <img
                  src={CircleGif}
                  alt=""
                  className="xl:h-[40rem] lg:h-[25rem] mx-auto text-center w-full"
                />
              </div>
              <div className=" flex top-0 floating-btn left-10 sm:top-0 sm:left-0  absolute  xl:top-0 md:top-0 lg:top-[-2rem] xl:left-[6rem] md:left-[1rem]  gap-4 items-center mt-3">
                <button className="hover:animate-bounce text-[12px] py-2 px-4  sm:py-2 sm:text-[12px] sm:px-3 xl:text-[20px] lg:text-[12px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3  theme-caret-orange font-bold text-sm relative bg-[#F16427] rounded-lg btn xl:py-5 xl:px-7 text-white">
                  Services
                </button>
              </div>
              <div className="flex top-0 right-10 floating-btn sm:top-0 sm:right-0  absolute  xl:top-10 lg:right-10  xl:right-10  xl:justify-end md:top-[-2rem] md:left-[14rem] gap-4 items-center mt-3">
                <button className="hover:animate-bounce text-[12px] py-2 px-4 sm:py-2 sm:text-[12px] sm:px-3  xl:text-[20px]  lg:text-[12px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3 transition-all duration-300 theme-caret font-bold text-sm relative bg-[#273B60] rounded-lg btn xl:py-5 xl:px-7 text-white">
                  Products
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroShop;
