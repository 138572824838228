// Pricing.js
import React from "react";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Card from './Card';


// Data for the 3-tier pricing table
export const plans = [
  {
    name: 'Free',
    price: 0,
    frequency: 'month',
    features: [
      'Create Free Shops',
      'Add Free Products',
      'Add Free Services',
    ],
    link: 'https://buy.stripe.com/test_8wMeYr3ln7UF7XGeUU', // Add link for Free plan
  },
  {
    name: 'Premium',
    price: 100,
    frequency: 'month',
    featured: true,
    features: [
      'Create Shops',
      'Add Products and Services',
      'Marketing on Social Channels',
      '24/7 Support',
    ],
    link: 'https://buy.stripe.com/test_PremiumPlanLink', // Add link for Premium plan
  },
  {
    name: 'Advanced',
    price: 399,
    frequency: 'month',
    features: [
      'Premium Package',
      'Maintenance',
      'Branding',
    ],
    link: 'https://buy.stripe.com/test_AdvancedPlanLink', // Add link for Advanced plan
  },
];

const Pricing = () => {
  return (
    <>
      <Navbar />
      <Hero />
	  &nbsp;
      <div className="h-full flex flex-col">
	  &nbsp;
        {/* Responsive 3-tier pricing table */}
        <main className="flex-1 px-6 py-12 lg:flex lg:justify-center lg:items-center">
		  <div className="grid gap-12 lg:gap-0 lg:grid-cols-3">
            {plans.map(plan => (
              <div
                key={plan.name}
                className={`w-full max-w-lg mx-auto ${
                  plan.featured
                    ? 'order-first lg:order-none lg:transform lg:scale-110 lg:z-10'
                    : 'lg:transform lg:scale-90'
                }`}
              >
                <Card {...plan} />
              </div>
            ))}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
