import { createSlice } from '@reduxjs/toolkit';

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    webInterface: 'business',
  },
  reducers: {
    currentWebInterface: (state, action) => {
      return {
        ...state,
        webInterface: action.payload,
      };
    },
  },
});

export const { currentWebInterface } = mainSlice.actions;
export default mainSlice.reducer;
