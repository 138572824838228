import React, { useState } from 'react';
import Logo from '../../constants/svg/Logo'; // Assuming Logo component is imported
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD_MUTATION } from '../../GraphQL/mutation';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Toaster from '../../common/Toaster';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for password visibility toggle
  const [error, setError] = useState({
    status: false,
    type: '',
    message: '',
  });
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!password) {
      newErrors.password = 'Password is required.';
    } else if (password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long.';
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const forgotPassword = async (password, username) => {
    try {
      const { data } = await forgotPasswordMutation({
        variables: { newPassword: password, user: username },
      });

      if (data.forgotPassword.status !== 200) {
        if (data.forgotPassword.status === 400) {
          setError({
            status: true,
            type: 'error',
            message: 'You have already used this password',
          });
        }
        setError({
          status: true,
          type: 'error',
          message: 'Something went wrong, please try again',
        });
        return 'Something went wrong, please try again';
      }
      navigate('/login');
      return 'Password has been reset successfully';
    } catch (error) {
      console.error(error);
      setError({
        status: true,
        type: 'error',
        message: 'Something went wrong, please try again',
      });
      return 'Something went wrong, please try again';
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const result = await forgotPassword(password, email);
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-white mx-auto my-8 mt-[10rem] px-4 font-poppins">
      {error.status && <Toaster {...error} changeError={setError} />}
      <Logo className="mx-auto mb-4" width="1750" height="140" />
      <h4
        className="text-center mb-5 text-xl text-F16427"
        style={{ marginLeft: 200 }}
      >
        Shopping Made Easy
      </h4>
      <h3 className="text-center mb-5 text-xl text-273B60">
        Choose a strong password
      </h3>
      <div className="form-container w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        <h4 className="text-center mb-8 text-xl text-273B60">Reset Password</h4>

        <form onSubmit={handleSubmit} className="w-full">
          {/* Email */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email *
            </label>
            <input
              type="email"
              id="email"
              className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                errors.email ? 'border-red-500' : ''
              }`}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          {/* Password */}
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              New Password *
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${errors.password ? 'border-red-500' : ''}`}
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {/* Toggle button */}
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 py-2 bg-transparent focus:outline-none"
                onClick={handlePasswordToggle}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5 text-gray-500" />
                ) : (
                  <FaEye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          {/* Confirm Password */}
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm Password *
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              className={`w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                errors.confirmPassword ? 'border-red-500' : ''
              }`}
              placeholder="Re-enter your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">
                {errors.confirmPassword}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 rounded-lg bg-[#F16427] text-white font-medium hover:bg-[#C6471A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F16427]"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
