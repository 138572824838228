
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = ({ featured = false, children = null, link, to }) => {
  const commonProps = {
    className: `whitespace-nowrap w-full hover:bg-indigo-900 hover:text-white py-4 px-8 rounded-lg text-lg tracking-wide focus:outline-none focus:ring-4 focus:ring-indigo-600 focus:ring-opacity-50 ${
      featured
        ? 'bg-indigo-600 hover:bg-indigo-900 text-white transform hover:scale-105 transition-all'
        : 'bg-white text-indigo-600'
    }`,
  };


  if (to) {
    return <Link to={to} {...commonProps}>{children}</Link>;
  } else if (link) {
    return <a href={link} {...commonProps}>{children}</a>;
  } else {
    return <button {...commonProps}>{children}</button>;
  }
};
  
Button.propTypes = {
  featured: PropTypes.bool,
  link: PropTypes.string, // Change to link
  to: PropTypes.string,
  children: PropTypes.node,
};

export default Button;