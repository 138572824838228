// Cart.jsx
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Navbar from './Navbar';
import {
  addItemToCart,
  removeItemFromCart,
  clearCart,
} from '../store/reducers/cart';

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook for navigation
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated); // Access isAuthenticated from user reducer
  const user = useSelector((state) => state.userinfo); 

  const handleAddItem = (item) => {
    dispatch(addItemToCart({ ...item, quantity: 1 }));
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItemFromCart(id));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  // Function to handle checkout navigation with authentication check
  const handleCheckout = () => {
    if (isAuthenticated) {
      console.log(user);
      navigate('/checkout'); // Navigate to the checkout page if authenticated
    } else {
      // Navigate to the signup page with the intended path
      navigate('/signup', { state: { from: '/checkout' } });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto my-20 px-4 mt-50">
        {cartItems.length > 0 ? (
          <div>
            <ul className="space-y-4">
              {cartItems.map((item) => (
                <li
                  key={item.id}
                  className="border border-gray-200 rounded-lg shadow-md p-4 bg-white"
                >
                  <img
                    src={`https://storage.googleapis.com/awkat-o-salat/${item?.imageName}`}
                    alt="Product"
                    className="w-small rounded-lg mr-4"
                  />
                  <div className="flex flex-wrap items-center">
                    <div className="flex-grow">
                      <h3 className="font-sans text-lg font-bold text-blue-gray-900">
                        {item.name}
                      </h3>
                      <p className="font-sans text-sm text-blue-gray-700">
                        {item.quantity} x ${item.price} = ${item.totalPrice}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => handleRemoveItem(item.id)}
                        className="px-3 py-1 text-white bg-red-500 rounded-md hover:bg-red-600"
                      >
                        -
                      </button>
                      <span className="mx-3 font-sans text-lg text-blue-gray-900">
                        {item.quantity}
                      </span>
                      <button
                        onClick={() => handleAddItem(item)}
                        className="px-3 py-1 text-white bg-green-500 rounded-md hover:bg-green-600"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-6 flex items-center justify-between">
              <h3 className="font-sans text-xl font-bold text-blue-gray-900">
                Total: ${totalAmount.toFixed(2)}
              </h3>
              <div className="flex gap-4">
                <button
                  onClick={handleClearCart}
                  className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600"
                >
                  Clear Cart
                </button>
                <button
                  onClick={handleCheckout}
                  className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p className="font-sans text-lg text-blue-gray-700">
            Your cart is empty
          </p>
        )}
      </div>
    </div>
  );
};

export default Cart;
