import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessInfo: {},
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusinessInfo: (state, action) => {
      return {
        ...state,
        businessInfo: action.payload,
      };
    },
  },
});

export const { setBusinessInfo } = businessSlice.actions;
export default businessSlice.reducer;
