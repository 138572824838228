import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import data from '../data.json';
import Navbar from './Navbar';
import Hero from './ShopHero';
import Footer from './Footer';
import { ProductCard, ProductSkeleton } from './ProductComponents';

const TagProducts = () => {
  const { tagName } = useParams();
  console.log('TagProducts rendered with tagName:', tagName);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Simulate API call
    setTimeout(() => {
      const tagData = data.data.getProductsByTag.data.find(
        (tag) => tag.tagName.toLowerCase() === tagName.toLowerCase(),
      );
      setProducts(tagData ? tagData.products : []);
      setLoading(false);
    }, 1000);
  }, [tagName]);

  const filteredProducts = products.filter((product) =>
    product.productName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      <Navbar />
      <Hero heading={`Shop ${tagName}`} className="mb-20" showSearch={false} />
      <div className="container mx-auto px-4 mt-8">
        <div className="my-10">
          <h4 class="xl:text-lg lg:text-lg md:text-base font-semibold font-poppins sm:text-base text-[14px]  md:mb-2">
            Search Best Deals
          </h4>
          <input
            type="text"
            placeholder="Search products..."
            className="w-[100%] xl:h-14 lg:h-12 md:h-10 sm:h-10 border pl-3 border-black border-solid"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {[...Array(10)].map((_, index) => (
              <ProductSkeleton key={index} />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 mb-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {filteredProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TagProducts;
