import React from 'react';
import { useTable } from 'react-table';

function DataTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div style={{ width: '100%', height: '375px', overflowY: 'auto' }}>
      {' '}
      {/* Adjust width and height as needed */}
      <table
        {...getTableProps()}
        style={{
          borderCollapse: 'collapse',
          minWidth: '100%',
          borderBottom: '1px solid black', // Add this line to apply bottom border
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    border: '1px solid black',
                    borderBottom: 'none', // Add this line to remove bottom border from table header cells
                    padding: '8px',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        border: '1px solid black',
                        padding: '8px',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;
