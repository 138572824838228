/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import React from 'react';
import Wave from 'react-wavify';
const Footer = () => {
  return (
    // <div
    //   data-aos=""
    //   id="contact"
    //   className="w-[100vw] relative top-[4vh] font-poppins "
    // >
    //   {/* UPPER PART  */}
    //   <div className=" z-50 flex justify-center items-center flex-col mb-[1rem] sm:mb-[0.6rem] ">
    //     <p className="text-white font-extrabold text-2xl mb-4 pt-[1rem]">
    //       SUBSCRIBE TO OUR NEWSLETTER
    //     </p>
    //     <h4 className="text-[50px] text-[#273B60]">
    //     Don't Miss Out
    //     </h4>
    //     <a
    //       href="mailto:contact@tajammul.app"
    //       className="text-[#00000] max-sm:text-[7px] text-[0.7rem] relative top-[1rem] sm:top-[1.5rem]"
    //     >
    //       contact@tajammul.app
    //     </a>
    //     <img
    //       src={AtTheRate}
    //       className="text-center h-[4rem] flex justify-center relative top-[-3rem] items-center"
    //     />
    //   </div>

    //   {/* MAIN FOOTER  */}

    //   <div className=" h-[24rem] relative bg-gradient-to-r from-gray-900 via-blue-900 to-blue-900">
    //     {/* UPPER EMAIL  */}

    //     {/* MAIN LINKS  */}
    // <div className="flex justify-center bg-gradient-to-r from-gray-900 via-blue-900 to-blue-900 items-start gap-x-10 sm:pt-[2rem] pt-[1rem] pl-3 pr-3 sm:pl-0 sm:pr-0 text-white w-screen">
    //   <div className="max-sm:pt-[1rem]">
    //     <p className="font-bold text-lg mb-2 sm:text-base max-sm:text-[14px]">
    //       Contact Us
    //     </p>
    //     <p className="text-xs max-sm:text-[10px] hover:text-[#6d96e0] transition-all ease-in-out cursor-pointer">
    //       @ contact@tajammul.app
    //     </p>
    //   </div>

    //   <div className="flex flex-col max-sm:pt-[1rem]">
    //     <p className="mb-2 font-bold text-lg sm:text-base max-sm:text-[14px]">
    //       Our Company
    //     </p>
    //     <a
    //       href="#about"
    //       className="mb-2 text-xs max-sm:text-[10px] cursor-pointer hover:text-[#6d96e0] transition-all ease-in-out"
    //     >
    //       About
    //     </a>
    //     <a
    //       href="#serviceOfferings"
    //       className="mb-2 text-xs max-sm:text-[10px] cursor-pointer hover:text-[#6d96e0] transition-all ease-in-out"
    //     >
    //       Product
    //     </a>
    //     <a
    //       href="#serviceOfferings"
    //       className="mb-2 text-xs max-sm:text-[10px] cursor-pointer hover:text-[#6d96e0] transition-all ease-in-out"
    //     >
    //       Services
    //     </a>
    //     <a
    //       href="#contact"
    //       className="mb-2 text-xs max-sm:text-[10px] cursor-pointer hover:text-[#6d96e0] transition-all ease-in-out"
    //     >
    //       Contact us
    //     </a>
    //     <Link
    //       to="/privacy-policy-business"
    //       className="text-xs pb-2 cursor-pointer max-sm:text-[10px] hover:text-[#6d96e0] transition-all ease-in-out"
    //     >
    //       Privacy Policy Business
    //     </Link>
    //     <Link
    //       to="/privacy-policy-customer"
    //       className="text-xs cursor-pointer max-sm:text-[10px] hover:text-[#6d96e0] transition-all ease-in-out"
    //     >
    //       Privacy Policy Customer
    //     </Link>
    //   </div>

    //   <div className="max-sm:pt-[1rem]">
    //     <p className="mb-6 font-bold text-lg max-sm:text-[14px]">
    //       Social Contacts
    //     </p>
    //     <div className="flex gap-x-2 items-center flex-wrap justify-between">
    //       <a href="https://www.facebook.com/tajammulapp/">
    //         <div
    //           style={{ border: "2px solid white" }}
    //           className="w-[1.5rem] hover:bg-[#6d96e0] cursor-pointer h-[1.5rem] rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
    //         >
    //           <FaFacebookF size={14} />
    //         </div>
    //       </a>
    //       <a href="https://twitter.com/TajammulApp">
    //         <div
    //           style={{ border: "2px solid white" }}
    //           className="w-[1.5rem] h-[1.5rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
    //         >
    //           <FaTwitter size={14} />
    //         </div>
    //       </a>
    //       <a href="https://www.linkedin.com/company/tajammul-app/">
    //         <div
    //           style={{ border: "2px solid white" }}
    //           className="w-[1.5rem] h-[1.5rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
    //         >
    //           <FaLinkedinIn size={14} />
    //         </div>
    //       </a>
    //       <a href="https://www.instagram.com/tajammul.app">
    //         <div
    //           style={{ border: "2px solid white" }}
    //           className="w-[1.5rem] h-[1.5rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
    //         >
    //           <FaInstagram size={14} />
    //         </div>
    //       </a>
    //       <a href="https://www.youtube.com/@TajammulApp">
    //         <div
    //           style={{ border: "2px solid white" }}
    //           className="w-[1.5rem] h-[1.5rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
    //         >
    //           <FaYoutube size={14} />
    //         </div>
    //       </a>
    //     </div>
    //   </div>
    // </div>

    //     {/* FOOTER IMAGE  */}
    //     <div className=" absolute bottom-0">
    //       <img
    //         src={FooterImage}
    //         alt=""
    //         className="h-[7rem] bg-cover w-[100vw]"
    //       />
    //     </div>

    //     {/* FOOTER COPYRIGHT  */}
    //     <div className=" absolute bottom-2">
    //       <p className="text-[#273B60] text-center w-screen text-sm max-sm:text-[12px]">
    //         Copyright © 2024 tajammul.app All Rights Reserved
    //       </p>
    //     </div>
    //   </div>

    //   {/* FOOTER TOP IMAGE  */}
    //   <div className="absolute top-0 -z-50 w-[100vw] bg-gradient-to-r from-gray-900 via-blue-900 to-blue-900">
    //     <img
    //       src={FooterImage}
    //       alt=""
    //       className="h-[10rem] bg-cover w-[100vw]  rotate-180"
    //     />
    //   </div>
    // </div>
    <footer className="bg-[#E0B503]">
      <div className="bg-[#E0B503] pb-[2rem]">
        <div className="container text-center pt-20 mx-auto">
          <p className="text-white font-extrabold xl:text-[24px] lg:text-[18px] sm:text-[16px] text-[16px] mb-2 pt-[1rem]">
            SUBSCRIBE TO OUR NEWSLETTER
          </p>
          <h4 className="xl:text-[70px] lg:text-[45px] sm:text-[35px] text-[28px] text-[#273B60] font-bold">
            Don't Miss Out
          </h4>
          <p className="text-white xl:text-[24px] lg:text-[18px] sm:text-[16px] text-[16px] italic font-medium  ">
            Get the latest on sales, special offer, new releases and more…
          </p>
        </div>
        <div className="form mt-10 px-5">
          <form action="#">
            <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex max-sm:justify-center sm:space-y-0">
              <div className="relative w-full">
                <label
                  for="email"
                  className="hidden mb-2 text-sm font-medium text-gray-900 "
                >
                  Email address
                </label>
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-white "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </div>
                <input
                  className="block p-3 pl-10 w-full placeholder-white  placeholder-opacity-100 outline-none text-sm text-white bg-[#E0B503] border border-white sm:rounded-none sm:rounded-l-lg focus:ring-white focus:border-white"
                  placeholder="Enter your email"
                  type="email"
                  id="email"
                  required=""
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-[#273B60] border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        {/* <Wave
          fill="#E0B503"
          className="bg-gradient-to-r rotate-180 from-blue-900 via-blue-900 to-gray-900"
          paused={true}
          style={{ display: "flex" }}
          options={{
            height: 50,
            amplitude: 100,
            points: 4,
          }}
        /> */}
      </div>
      <div className="bg-gradient-to-r from-gray-900 via-blue-900 to-blue-900 ">
        <div className="container mx-auto text-left py-5">
          <div className="container">
            <div className="flex justify-start  items-start sm:pt-[1rem] pt-[1rem]  sm:pl-0 sm:pr-0 text-white w-full">
              <div className="w-full">
                <div className="flex justify-start  items-top">
                  <div className="w-4/12">
                    <div className="max-sm:pt-[1rem] max-smpx-3">
                      <p className="font-bold xl:text-[24px] lg:text-[18px] sm:text-[16px] text-[16px]  mb-2">
                        Contact Us
                      </p>
                      <p className="text-xs max-sm:text-[12px] hover:text-[#E0B503] transition-all ease-in-out cursor-pointer">
                        @ contact@tajjam.com
                      </p>
                    </div>
                  </div>
                  <div className="w-4/12">
                    <div className="flex flex-col max-sm:pt-[1rem]">
                      <p className="mb-2 font-bold xl:text-[24px] lg:text-[18px] sm:text-[16px] text-[16px] ">
                        Our Company
                      </p>
                      <a
                        href="#about"
                        className="mb-2 text-xs max-sm:text-[12px] cursor-pointer hover:text-[#E0B503] transition-all ease-in-out"
                      >
                        About
                      </a>
                      <a
                        href="#serviceOfferings"
                        className="mb-2 text-xs max-sm:text-[12px] cursor-pointer hover:text-[#E0B503] transition-all ease-in-out"
                      >
                        Product
                      </a>
                      <a
                        href="#serviceOfferings"
                        className="mb-2 text-xs max-sm:text-[12px] cursor-pointer hover:text-[#E0B503] transition-all ease-in-out"
                      >
                        Services
                      </a>
                      <a
                        href="#contact"
                        className="mb-2 text-xs max-sm:text-[12px] cursor-pointer hover:text-[#E0B503] transition-all ease-in-out"
                      >
                        Contact us
                      </a>
                      <Link
                        to="/privacy-policy-business"
                        className="text-xs pb-2 cursor-pointer max-sm:text-[12px] hover:text-[#E0B503] transition-all ease-in-out"
                      >
                        Privacy Policy Business
                      </Link>
                      <Link
                        to="/privacy-policy-customer"
                        className="text-xs cursor-pointer max-sm:text-[12px] hover:text-[#E0B503] transition-all ease-in-out"
                      >
                        Privacy Policy Customer
                      </Link>
                    </div>
                  </div>
                  <div className="w-4/12">
                    <div className="max-sm:pt-[1rem]">
                      <p className="mb-6 font-bold xl:text-[24px] lg:text-[18px] sm:text-[16px] text-[16px] ">
                        Social Contacts
                      </p>
                      <div className="flex gap-x-2 items-center flex-wrap justify-start">
                        <a href="https://www.facebook.com/tajammulapp/">
                          <div
                            style={{ border: '2px solid white' }}
                            className="w-[3rem] h-[3rem] hover:bg-[#6d96e0] p-1 cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
                          >
                            <FaFacebookF size={16} />
                          </div>
                        </a>
                        <a href="https://twitter.com/TajammulApp">
                          <div
                            style={{ border: '2px solid white' }}
                            className="w-[3rem] h-[3rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
                          >
                            <FaTwitter size={16} />
                          </div>
                        </a>
                        <a href="https://www.linkedin.com/company/tajammul-app/">
                          <div
                            style={{ border: '2px solid white' }}
                            className="w-[3rem] h-[3rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
                          >
                            <FaLinkedinIn size={16} />
                          </div>
                        </a>
                        <a href="https://www.instagram.com/tajammul.app">
                          <div
                            style={{ border: '2px solid white' }}
                            className="w-[3rem] h-[3rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
                          >
                            <FaInstagram size={16} />
                          </div>
                        </a>
                        <a href="https://www.youtube.com/@TajammulApp">
                          <div
                            style={{ border: '2px solid white' }}
                            className="w-[3rem] h-[3rem] hover:bg-[#6d96e0] cursor-pointer rounded-full flex justify-center items-center max-sm:w-[1.3rem] max-sm:h-[1.3rem]"
                          >
                            <FaYoutube size={16} />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="w-5/12 xl:block lg:block hidden"> &nbsp;</div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flip rotate-180">
      <Wave
        fill="#E0B503"
        paused={true}
        className="bg-gradient-to-r rotate-180 from-gray-900 via-blue-900 to-blue-900"
        style={{ display: "flex" }}
        options={{
          height: 50,
          amplitude: 100,
          points: 4,
        }}
      />
    </div> */}
      <div className="bg-[#E0B503] bg-bottom">
        <div className="container text-center mx-auto  pt-2 pb-1">
          <p className="text-black font-extrabold  text-sm mb-4 pt-[1rem]">
            Copyright © 2024 tajjam. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
