import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { useLocation } from 'react-router-dom';
import MapPicker from './MapPicker';
import { useMutation } from '@apollo/client';
import { CREATE_BUSINESS } from '../../GraphQL/mutation';
import BusinessHours from './BusinessHours';

const Index = () => {
  const [createBusiness] = useMutation(CREATE_BUSINESS);

  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shopName, setShopName] = useState('');
  const [salesTax, setSalesTax] = useState('');
  const [ntn, setNtn] = useState('');
  const [businessType, setBusinessType] = useState(null);
  const [addressDetails, setAddressDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [businessId, setBusinessId] = useState(null);

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname } });
    }
  }, [isAuthenticated, navigate]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleBusinessTypeChange = (type) => {
    setBusinessType(type);
  };

  const handleAddressSelect = (address) => {
    setAddressDetails(address); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const businessInfoPayload = {
      businessInfo: {
        name: shopName,
        ntn: ntn,
        salesTax: salesTax,
        imagePath: selectedImage ? URL.createObjectURL(selectedImage) : null,
        listImagePath: null,
        businessTypes: businessType ? [businessType] : [],
        address: {
          street: addressDetails.street || "",
          area: addressDetails.area || "",
          city: addressDetails.city || "",
          province: addressDetails.province || "",
          country: addressDetails.country || "",
          postalCode: addressDetails.postalCode || "",
          longitude: addressDetails.longitude || "",
          latitude: addressDetails.latitude || "",
          residence: addressDetails.residence || "",
          additionalAddress: addressDetails.additionalAddress || "",
          formatedAddress: addressDetails.formattedAddress || "",
          addressName: addressDetails.addressName || null,
        }
      }
    };
  
    try {
      const response = await createBusiness({ variables: { input: businessInfoPayload } });
      console.log("Response from server:", response.data.createBusiness);
  
      const { status, data } = response.data.createBusiness;
  
      
      if (status === 201 && data && data.businessId) {
        setBusinessId(data.businessId);
        setIsModalOpen(true);
        alert(`Business created successfully!`);
      } else {
        alert('Business creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating business:', error);
      alert('User is associated with another business.');
    }
  };
  
  

  return (
    <div style={styles.container}>
      <Navbar />

      <div style={styles.mainContent}>
        <h1>Register Your Business</h1>
        <p style={styles.subtitle}>Tajammul is an e-commerce solution providing online grocery and services</p>

        <div style={styles.card}>
          <h2 style={styles.cardTitle}>Shop Banner</h2>
          <p style={styles.cardSubtitle}>Add an image if you want to display it on your shop</p>

          <div style={styles.imageUploadContainer}>
            {selectedImage ? (
              <img src={URL.createObjectURL(selectedImage)} alt="Uploaded"  style={styles.uploadedImage} />
            ) : (
              <div style={styles.imagePlaceholder}>
                <span style={styles.imageText}>(PNG, JPG, JPEG: Max Size: 5MB)</span>
              </div>
            )}
            <input type="file" accept="image/*" onChange={handleImageChange} style={styles.fileInput} />
          </div>

          <button style={styles.continueButton}>Continue</button>
        </div>

        <form onSubmit={handleSubmit} style={styles.card}>
          <h2 style={styles.cardTitle}>Business Details</h2>
          <input
            type="text"
            placeholder="Shop name *"
            style={styles.input}
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
          />
          
          <MapPicker onAddressSelect={handleAddressSelect} />

          <input
            type="text"
            placeholder="Sales Tax Number"
            style={styles.input}
            value={salesTax}
            onChange={(e) => setSalesTax(e.target.value)}
          />
          <input
            type="text"
            placeholder="NTN"
            style={styles.input}
            value={ntn}
            onChange={(e) => setNtn(e.target.value)}
          />
          <div style={styles.checkboxContainer}>
            <label>
              <input
                type="checkbox"
                checked={businessType === 'SERVICE'}
                onChange={() => handleBusinessTypeChange('SERVICE')}
              />
              Service Provider
            </label>
            <label>
              <input
                type="checkbox"
                checked={businessType === 'PRODUCT'}
                onChange={() => handleBusinessTypeChange('PRODUCT')}
              />
              Seller's Account
            </label>
          </div>
          <button type="submit" style={styles.createAccountButton}>Create a Business Account</button>
          <p style={styles.termsText}>
            By Signing up you agree to our <a href="#" style={styles.link}>Privacy Policy and Terms</a>
          </p>
        </form>
        
      </div>

     
      

      <Footer />
       {/* Business Hours Modal */}
       {isModalOpen && (
        <div style={styles.overlay}>
          <BusinessHours businessId={businessId} onClose={() => setIsModalOpen(false)} />
          {/* <BusinessHours businessId={businessId} onClose={handleCloseModal} /> */}

        </div>
      )}
    </div>
  );
};
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    width: '100%',
  },
  mainContent: {
    padding: '20px',
    width: '100%',
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '100px',
  },
  subtitle: {
    color: '#7f7f7f',
    fontSize: '14px',
    marginTop: '10px',
    textAlign: 'center',
  },
  card: {
    backgroundColor: '#f8f8f8',
    padding: '20px',
    borderRadius: '10px',
    marginTop: '20px',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
  cardSubtitle: {
    fontSize: '14px',
    color: '#7f7f7f',
    margin: '10px 0',
  },
  imageUploadContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height:'50%'
  },
  imagePlaceholder: {
    backgroundColor: '#eaeaea',
    height: '50px',
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
  },
  uploadedImage: {
    width: '50%',
    height: '500px',
    borderRadius: '10px',
  },
  imageText: {
    fontSize: '12px',
    color: '#7f7f7f',
  },
  fileInput: {
    marginTop: '10px',
    padding: '10px',
  },
  continueButton: {
    backgroundColor: '#ff6b00',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  createAccountButton: {
    backgroundColor: '#ff6b00',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    width: '100%',
  },
  termsText: {
    fontSize: '12px',
    color: '#7f7f7f',
    marginTop: '10px',
    textAlign: 'center',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
  hrs:{
display:'flex',
justifyContent:'center',
alignItems:'center'
  },

  modal: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '10px',
    marginTop: '20px',
    width:'40%',
  
  },
  modalTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
  },
  dayCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
  timeInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timeText: {
    fontSize: '14px',
    color: '#333',
  },
  okButton: {
    backgroundColor: '#ff6b00',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    width: '100%',
  },
  card: {
    backgroundColor: '#f8f8f8',
    padding: '20px',
    borderRadius: '10px',
    marginTop: '20px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};


export default Index;
